import React from 'react'
import $ from 'jquery';

const AbMod = () => {

const closeAbMod = () => {
$('#casResModal_ab').hide();
document.getElementById('andarCardMod').innerHTML = '';
document.getElementById('baharCardMod').innerHTML = '';
}

const andarLeftMod = () => {
var ele = document.getElementById('andarCardMod');
ele.scrollLeft -= 10;
}

const andarRightMod = () => {
var ele = document.getElementById('andarCardMod');
ele.scrollLeft += 10;  
}

const baharLeftMod = () => {
var ele = document.getElementById('baharCardMod');
ele.scrollLeft -= 10;
}

const baharRightMod = () => {
var ele = document.getElementById('baharCardMod');
ele.scrollLeft += 10;
}

return (
<>
<div className='casResModal' id='casResModal_ab'>
<div className='casResCont'>
<div className='casResHead'><span>details result</span> <i className='fa fa-close' onClick={closeAbMod}></i></div>
<div className='casResBody'>

<div className='casDetResLoad_ab'>
<span></span>
</div>

<div className='resCasCon_ab'>
<div className='cardsRowGrid'>
<div className='collong'>
<span className='cardTeamCas'>andar card</span>
<div className='modCardsWrap'>
<div className='navCard' onClick={andarLeftMod}><span><i className='fa fa-angle-left'></i></span></div>
<div className='cardsImg' id='andarCardMod'></div>
<div className='navCard' onClick={andarRightMod}><span><i className='fa fa-angle-right'></i></span></div>
</div>
</div>

<div className='collong'>
<span className='cardTeamCas'>bahar card</span>
<div className='modCardsWrap'>
<div className='navCard' onClick={baharLeftMod}><span><i className='fa fa-angle-left'></i></span></div>
<div className='cardsImg' id='baharCardMod'></div>
<div className='navCard' onClick={baharRightMod}><span><i className='fa fa-angle-right'></i></span></div>
</div>
</div>
</div>

<div className='casResWin'>
<span className='casRestxt'>mid : <span id='winCas_abMid'></span></span>
</div>
</div>

<div className='record_no' id='record_no_ab'>
<img src='/images/poker_rec.png' alt='poker_rec' />   
<span>no records found...</span>
</div>

</div>
</div>
</div>
</>
)
}

export default AbMod;
