import axios from 'axios';
import $ from 'jquery';

var start_dt1day;

async function check_dt1day(){
const getData = await axios.get('/server/active-customer');
const resData = await getData.data;
//console.log(resData);
if(resData.length > 0){
start_dt1day = setInterval(fetchdt1day, 1000);
}
else{
console.log('login error');
}
}

async function fetchdt1day(){
try {
const getData = await axios.get('/server/dt1day_Data');
const resData = await getData.data;
//console.log('refresh');
if(resData.data === undefined){
clearInterval(start_dt1day);
setTimeout(function(){
start_dt1day = setInterval(fetchdt1day, 1000);
},1000);
}
else{
var currMid = $('#roundId_dt1day').text();
if(resData.data.t1[0].mid !== currMid){
$('.dt1dayPls').text('0').css({color: 'black'});
$('#allMarket_bets').hide();
}
$('#autoTime_dt1day').text(resData.data.t1[0].autotime);
$('#roundId_dt1day').text(resData.data.t1[0].mid);

$('#card1_dt1day').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c1.toUpperCase()+'.jpg');
$('#card2_dt1day').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c2.toUpperCase()+'.jpg');

resData.data.t2.forEach(element => {
if(element.gstatus !== 'active'){
$('#locCasino_dt1day_'+element.sid).css({display: 'flex'});
$('#odds_casBack_dt1day_'+element.sid).attr('disabled', 'disabled');
$('#odds_casLay_dt1day_'+element.sid).attr('disabled', 'disabled');
$('#casRateBack_dt1day_'+element.sid).text(element.b1);
$('#casSizeBack_dt1day_'+element.sid).text(element.bs1);
$('#casRateLay_dt1day_'+element.sid).text(element.l1);
$('#casSizeLay_dt1day_'+element.sid).text(element.ls1);
$('#eventid_dt1day_'+element.sid).text(resData.data.t1[0].mid);
}
else{
$('#locCasino_dt1day_'+element.sid).hide(); 
$('#eventid_dt1day_'+element.sid).text(resData.data.t1[0].mid);
if(element.b1 === '0.00'){
$('#odds_casBack_dt1day_'+element.sid).attr('disabled', 'disabled');
$('#casRateBack_dt1day_'+element.sid).text(element.b1);
$('#casSizeBack_dt1day_'+element.sid).text(element.bs1);
}
else{
$('#odds_casBack_dt1day_'+element.sid).removeAttr('disabled');
$('#casRateBack_dt1day_'+element.sid).text(element.b1);
$('#casSizeBack_dt1day_'+element.sid).text(element.bs1);
}

if(element.l1 === '0.00'){
$('#odds_casLay_dt1day_'+element.sid).attr('disabled', 'disabled');
$('#casRateLay_dt1day_'+element.sid).text(element.l1);
$('#casSizeLay_dt1day_'+element.sid).text(element.ls1);
}
else{
$('#odds_casLay_dt1day_'+element.sid).removeAttr('disabled');
$('#casRateLay_dt1day_'+element.sid).text(element.l1);
$('#casSizeLay_dt1day_'+element.sid).text(element.ls1);
}
}
});
}
} catch (error) {
clearInterval(start_dt1day);
setTimeout(function(){
start_dt1day = setInterval(fetchdt1day, 1000);
},1000);
console.log('frontend refresh_dt1day_data error : ' + error);
}
}

export {
check_dt1day,
start_dt1day
};