
import $ from 'jquery';
import axios from 'axios';
import { getAllBetsMarket } from '../../../allBets/Betsmarket';

var allSid_dt20 = [];

function oddsClick_dt20(sid,team,rate){
$('#teamCas_dt20').text(team);
$('#bet_rate_dt20').val(rate);
$('#bet_amount_dt20').val(0);
$('#teamSid_dt20').text(sid);
$('#placeBetCasino_dt20').show();
$('#betsPlace_dt20').removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_dt20').removeClass('active').attr('disabled', 'disabled');
}

function cancelBet_dt20(){
$('#placeBetCasino_dt20').hide();
$('#teamCas_dt20').text('');
$('#bet_rate_dt20').val();
$('#teamSid_dt20').text('');
}

function minusAmt_dt20(){
let betAmt = $('#bet_amount_dt20').val();
$('#bet_amount_dt20').val((parseFloat(betAmt) / 2).toFixed());
}

function plusAmt_dt20(){
let betAmt = $('#bet_amount_dt20').val();
$('#bet_amount_dt20').val((parseFloat(betAmt) + parseFloat(betAmt)).toFixed());
}

function changeAmt_dt20(val){
if(parseFloat(val !== '' ? val : 0) <= 0){
$('#betsPlace_dt20').removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_dt20').removeClass('active').attr('disabled', 'disabled');
$('#bet_amount_dt20').val(0);
}else{
$('#betsPlace_dt20').addClass('active').removeAttr('disabled'); 
$('#betsPlace2_dt20').addClass('active').removeAttr('disabled');    
$('#bet_amount_dt20').val(Math.round(val));
}
}

function stakeBet_dt20(amt){
$('#bet_amount_dt20').val(amt);
$('#betsPlace_dt20').addClass('active').removeAttr('disabled');
$('#betsPlace2_dt20').addClass('active').removeAttr('disabled');
}

function betSend_dt20(){
$('#betMsgWrapCas_dt20').css({display: 'grid'});
setTimeout(function(){
finalPlaceBet_dt20();
},2000);
}

async function finalPlaceBet_dt20(){
var selBetType = $('#selBetType_dt20').text();
var sec = $('#sec_dt20').text();
var sport = $('#sport_dt20').text();
var eventname = 'casino';
var teamCas_dt20 = $('#teamCas_dt20').text();
var bet_rate_dt20 = $('#bet_rate_dt20').val();
var bet_amount_dt20 = $('#bet_amount_dt20').val();
var eventid = $('#eventid_dt20').text();
var userCurr = $('#userCurr_dt20').text();
var betdelay = 2;
let sid = $('#teamSid_dt20').text();

let minBet = 0;
if(userCurr === 'inr'){
minBet = 100;
}
else{
minBet = 1;  
}

if(eventid === '0'){
$('#bet_msgCas_dt20').show().html('roundid changed');
setTimeout(function(){
$('#betMsgWrapCas_dt20').css({display: 'none'});
$('#bet_msgCas_dt20').hide().html('');
},1000);
return false;
}
if(bet_amount_dt20 === '' || bet_amount_dt20 < minBet){
$('#bet_msgCas_dt20').show().html('minimum betamount ' + minBet);
setTimeout(function(){
$('#betMsgWrapCas_dt20').css({display: 'none'});
$('#bet_msgCas_dt20').hide().html('');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server/casino_saveBet', {
selBetType: selBetType,
sec: sec,
sport: sport,
eventname: eventname,
teamCas: teamCas_dt20,
bet_rate: bet_rate_dt20,
bet_amount: bet_amount_dt20,
eventid: eventid,
betdelay: betdelay,
sid: sid
});
const resData = await sendData.data;
if(resData === 'success'){
setExposure();
getAllBetsMarket(eventid);
pls_dt20(eventid,sport);
$('#bet_msgCas_dt20').show().html('bet placed');
setTimeout(function(){
$('#betMsgWrapCas_dt20').css({display: 'none'});
$('#bet_msgCas_dt20').hide().html('');
cancelBet_dt20();
},1000);
return false;
}
else{
$('#bet_msgCas_dt20').show().html(resData);
setTimeout(function(){
$('#betMsgWrapCas_dt20').css({display: 'none'});
$('#bet_msgCas_dt20').hide().html('');
cancelBet_dt20();
},1000);
}
} catch (error) {
console.log('frontend dt20_send_savebet data : ' + error);
}
}
}

async function setExposure(){
let currBal = 0;
let currExpo = 0;
const getBal = await axios.get('/server/active-customer');
const balRes = await getBal.data;
if(balRes.length > 0){
currBal = balRes[0].total_balance;
}

const getExpo = await axios.get('/server/user-exposure');
const expoRes = await getExpo.data;
if(expoRes.length > 0){
if(expoRes[0].exposure !== null){
currExpo = expoRes[0].exposure;
}
}

$('#mainBal').html(parseFloat((currBal - currExpo).toFixed(2)).toLocaleString());
$('#mainExpo').html(parseFloat((currExpo).toFixed(2)).toLocaleString());
//console.log('curr balance : '+currBal +' exposure : '+ currExpo);

}

async function pls_dt20(eventid,sport){
//console.log(allSid_dt20);
try {
const getData = await axios.get('/server/all-bets');
const resData = await getData.data;
const response = resData.filter((e) => e.sport === sport && e.eventid === eventid);
if(response.length > 0){
const reduceData = response.reduce((c, e) => {
const { team, loss } = e;
const cteam = c.find((h) => h.team === team);
let lossNew = 0;
lossNew = lossNew + loss;
cteam ? cteam.lossNew += lossNew : c.push({team,lossNew});
return c;
},[]);

//console.log(reduceData);

reduceData.length > 0 && reduceData.forEach(element => {
allSid_dt20.forEach((ele) => {
if(element.team === ele.nation){
$('#dt20_Pls_'+ele.sid).html(parseFloat(element.lossNew.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
});
});

}
} catch (error) {
console.log('frontend dt20_pls bets api : ' + error);
}
}

export {
cancelBet_dt20,
minusAmt_dt20,
plusAmt_dt20,
changeAmt_dt20,
stakeBet_dt20,
betSend_dt20,
oddsClick_dt20,
allSid_dt20,
pls_dt20
};