import $ from 'jquery';
import axios from 'axios';
import { getAllBetsMarket } from '../../../allBets/Betsmarket';

let marketId = 0;
let lu09Count = 0;
var startCouAutlu09;
var spinRunTime;
var timeOut1;
var timeOut2;
var timeOut3;
let audioPla = new Audio('/audio/spinning-wheel.m4a');

function lucky09Mid(){
marketId = '14.' + Math.floor((Math.random() * 1500000 * Date.now()).toString().slice(0, 10));
//eventId =  Math.floor((Math.random() * 1300000 * Date.now()).toString().slice(0, 10));
$('#marketid_lucky09').text(marketId);
$('#eventid_lu09').text(marketId);
}

function runTimerLu09(){
lu09Count = 21;
setTimeout(() => {
startCouAutlu09 = setInterval(startCount_lu09, 1000);  
}, 2000);
}

function startCount_lu09(){
if(lu09Count <= 0){
$('#countstart_lu09').text(lu09Count);
clearInterval(startCouAutlu09);
spinRunTime = setTimeout(spinRun, 3000);
}
else{
lu09Count--;
$('#countstart_lu09').text(lu09Count);
if(lu09Count <= 3){
$('.lockLu096').css({display: 'flex'});
$('.lucky09_oddsBtn').attr('disabled', 'disabled');
cancelBet_lu09();
}
}
}

function spinRun(){
var x = 1024; //min value
var y = 9999; // max value
var mid = $('#marketid_lucky09').text();
var result = null;
let extraDegree = Math.floor(Math.random() * (x - y)) + y;
//extraDegree += Math.ceil(Math.random() * 10000);
document.getElementById('wheelspin').style.transition = '5s';
document.getElementById('wheelspin').style.transform = "rotate("+ extraDegree +"deg)";
//console.log('after degree ' + extraDegree);
playSoundLucky09(true);

timeOut1 = setTimeout(() => {
while (extraDegree >= 360) {
extraDegree = extraDegree - 360;
//console.log('time degree ' + extraDegree);
}
//console.log('before degree ' + extraDegree);

if(extraDegree >= 0 && (extraDegree < 19 || (extraDegree > 343 && extraDegree < 361))){
$('.wheeldiv_1').addClass('activeWheel');
result = '0';
//console.log('win spin '+ 0);
}
else if(extraDegree >= 19 && extraDegree < 55){
$('.wheeldiv_10').addClass('activeWheel');
result = '9';
//console.log('win spin '+ 9);
}
else if(extraDegree >= 55 && extraDegree < 91){
$('.wheeldiv_9').addClass('activeWheel');
result = '8';
//console.log('win spin '+ 8);
}
else if(extraDegree >= 91 && extraDegree < 127){
$('.wheeldiv_8').addClass('activeWheel');
result = '7';
//console.log('win spin '+ 7);
}
else if(extraDegree >= 127 && extraDegree < 163){
$('.wheeldiv_7').addClass('activeWheel');
result = '6';
//console.log('win spin '+ 6);
}
else if(extraDegree >= 163 && extraDegree < 199){
$('.wheeldiv_6').addClass('activeWheel');
result = '5';
//console.log('win spin '+ 5);
}
else if(extraDegree >= 199 && extraDegree < 235){
$('.wheeldiv_5').addClass('activeWheel');
result = '4';
//console.log('win spin '+ 4);
}
else if(extraDegree >= 235 && extraDegree < 271){
$('.wheeldiv_4').addClass('activeWheel');
result = '3';
//console.log('win spin '+ 3);
}
else if(extraDegree >= 271 && extraDegree < 307){
$('.wheeldiv_3').addClass('activeWheel');
result = '2';
//console.log('win spin '+ 2);
}
else if(extraDegree >= 271 && extraDegree < 343){
$('.wheeldiv_2').addClass('activeWheel');
result = '1';
//console.log('win spin '+ 1);
}

//console.log('ok spin '+ extraDegree);
saveResultLu09(mid,result);
},5000);

timeOut2 = setTimeout(() => {
$('#marketid_lucky09').text('0');
$('.wheeldiv').removeClass('activeWheel');
$('#allMarket_bets').hide();
document.getElementById('wheelspin').style.transition = 'none';
document.getElementById('wheelspin').style.transform = "rotate(0)";
},10000);

timeOut3 = setTimeout(() => {
lucky09Mid();
lu09Count = 21;
$('.lockLu096').css({display: 'none'});
$('.lucky09_oddsBtn').removeAttr('disabled');
startCouAutlu09 = setInterval(startCount_lu09, 1000);  
settle_lu09();
resultLucky09();
},15000);
}

function playSoundLucky09(val){
if(val === true){
//console.log('play');
audioPla.play();
}
else{
//console.log('stop');
audioPla.pause();
}
}

async function saveResultLu09(mid,result){
if(mid !== '0' && result !== null){
//console.log(mid +' -- '+ result);
try {
const sendData = await axios.post('/server/saveResult_vlu09/', {
mid: mid,
winner: result
});
const resData = await sendData.data;
if(resData === 'success'){
//console.log(resData);
}
else{
//console.log(resData);  
}
} catch (error) {
console.log('frontend save_result_v_lu09 error : ' + error);
}
}
}

function oddsClickLu09(team){
$('#bet_amount_lu09').val(0);
$('#teamCas_lu09').text(team);
$('#placeBetCasino_lu09').show();
$('#betsPlace_lu09').removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_lu09').removeClass('active').attr('disabled', 'disabled');
}

function cancelBet_lu09(){
$('#placeBetCasino_lu09').hide();
$('#teamCas_lu09').text('');
}

function stakeBet_lu09(amt){
$('#bet_amount_lu09').val(amt);
$('#betsPlace_lu09').addClass('active').removeAttr('disabled');
$('#betsPlace2_lu09').addClass('active').removeAttr('disabled');
}

function betSend_lu09(){
$('#betMsgWrapCas_lu09').css({display: 'grid'});
setTimeout(function(){
finalPlaceBet_lu09();
},1000);
}

async function finalPlaceBet_lu09(){
var selBetType = $('#selBetType_lu09').text();
var sec = $('#sec_lu09').text();
var sport = $('#sport_lu09').text();
var eventname = 'virtual casino';
var teamCas = 'lucky no '+ $('#teamCas_lu09').text();
var bet_rate = '2';
var bet_amount = $('#bet_amount_lu09').val();
var eventid = $('#eventid_lu09').text();
var userCurr = $('#userCurr_lu09').text();
var betdelay = 1;
let sid = $('#teamSid_lu09').text();

var checkTeam = $('#teamCas_lu09').text();
if(checkTeam === ''){
$('#betMsgWrapCas_lu09').css({display: 'none'});
cancelBet_lu09(); 
return false;
}

let minBet = 0;
if(userCurr === 'inr'){
minBet = 100;
}
else{
minBet = 1;  
}

if(eventid === '0'){
$('#bet_msgCas_lu09').show().html('roundid changed');
setTimeout(function(){
$('#betMsgWrapCas_lu09').css({display: 'none'});
$('#bet_msgCas_lu09').hide().html('');
},1000);
return false;
}
if(bet_amount === '' || bet_amount < minBet){
$('#bet_msgCas_lu09').show().html('minimum betamount ' + minBet);
setTimeout(function(){
$('#betMsgWrapCas_lu09').css({display: 'none'});
$('#bet_msgCas_lu09').hide().html('');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server/casino_saveBet', {
selBetType: selBetType,
sec: sec,
sport: sport,
eventname: eventname,
teamCas: teamCas,
bet_rate: bet_rate,
bet_amount: bet_amount,
eventid: eventid,
betdelay: betdelay,
sid: sid
});
const resData = await sendData.data;
if(resData === 'success'){
setExposure();
getAllBetsMarket(eventid);
$('#bet_msgCas_lu09').show().html('bet placed');
setTimeout(function(){
$('#betMsgWrapCas_lu09').css({display: 'none'});
$('#bet_msgCas_lu09').hide().html('');
cancelBet_lu09();
},1000);
return false;
}
else{
$('#bet_msgCas_lu09').show().html(resData);
setTimeout(function(){
$('#betMsgWrapCas_lu09').css({display: 'none'});
$('#bet_msgCas_lu09').hide().html('');
cancelBet_lu09();
},1000);
}
} catch (error) {
console.log('frontend lu09_send_savebet data : ' + error);
}
}
}

async function setExposure(){
let currBal = 0;
let currExpo = 0;
const getBal = await axios.get('/server/active-customer');
const balRes = await getBal.data;
if(balRes.length > 0){
currBal = balRes[0].total_balance;
}

const getExpo = await axios.get('/server/user-exposure');
const expoRes = await getExpo.data;
if(expoRes.length > 0){
if(expoRes[0].exposure !== null){
currExpo = expoRes[0].exposure;
}
}

$('#mainBal').html(parseFloat((currBal - currExpo).toFixed(2)).toLocaleString());
$('#mainExpo').html(parseFloat((currExpo).toFixed(2)).toLocaleString());
//console.log('curr balance : '+currBal +' exposure : '+ currExpo);

}

async function settle_lu09(){
try {
const getData = await axios.get('/server/lucky_09_settle');
const resData = await getData.data;
if(resData === 'success'){
//console.log('settle lucky_09 clear'); 
setExposure();
}
else{
//console.log(resData);  
}
} catch (error) {
console.log('frontend lucky_096 settle error : ' + error);
}
}

async function resultLucky09() {
try {
const getData = await axios.get('/server/lucky_09_result');
const resData = await getData.data;
//console.log(resData);
if(resData.length > 0){
document.getElementById('result_lu09').innerHTML = '';
resData.forEach(element => {
var winnerFirst = element.winner;
document.getElementById('result_lu09').innerHTML += `<span class='activeReddark'>${winnerFirst}</span>`;
});
}
} catch (error) {
console.log('frontend lucky_09_result error : ' + error);
}  
}

export {
spinRun,
lucky09Mid,
runTimerLu09,
startCouAutlu09,
oddsClickLu09,
cancelBet_lu09,
betSend_lu09,
stakeBet_lu09,
spinRunTime,
timeOut1,
timeOut2,
timeOut3,
settle_lu09,
resultLucky09,
playSoundLucky09
}