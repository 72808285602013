import axios from 'axios';
import $ from 'jquery';

var runSettle_t201day;

function startSettle_t201day(){
runSettle_t201day = setInterval(autoSettle_t201day, 10000);  
}

async function autoSettle_t201day(){
try {
//console.log('settlement run');
const getData = await axios.get('/server/t201DaySettle');
const resData = await getData.data;
if(resData === 'success'){
//console.log('settlement clear');
setExposure();
}
else{
console.log(resData);
}
} catch (error) {
console.log('frontend autoSettle_t201day_data error : ' + error);
}
}

async function setExposure(){
let currBal = 0;
let currExpo = 0;
const getBal = await axios.get('/server/active-customer');
const balRes = await getBal.data;
if(balRes.length > 0){
currBal = balRes[0].total_balance;
}

const getExpo = await axios.get('/server/user-exposure');
const expoRes = await getExpo.data;
if(expoRes.length > 0){
if(expoRes[0].exposure !== null){
currExpo = expoRes[0].exposure;
}
}

$('#mainBal').html(parseFloat((currBal - currExpo).toFixed(2)).toLocaleString());
$('#mainExpo').html(parseFloat((currExpo).toFixed(2)).toLocaleString());
//console.log('curr balance : '+currBal +' exposure : '+ currExpo);

}

export {
runSettle_t201day,
startSettle_t201day
};