import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const NewPass = () => {

const closeNewPass = () => {
$('#newPassModal').hide();
$('.fnewPassVal').val('');
}

const chnPassWay = async() => {
var pass = $('#forPass_new').val();
var conf = $('#forPass_conf').val();
var mail = $('#forPass_Ma').val();

if(pass === '' || pass.length < 6){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('6 value password required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;  
}
if(pass !== conf){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('confirm password no match');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;  
}
else{
try {
const sendData = await axios.post('/server/newPass_upd', {
conf: conf,
mail: mail
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('password updated successfully');
setTimeout(() => {
$('#msgAlert').hide();
$('#newPassModal').hide();
$('.fnewPassVal').val('');
},1000);
return false;   
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);  
}
} catch (error) {
console.log('frontend newPass_withOtp_update error : ' + error);
}
}
}

return (
<>
<div className='signUpModal' id='newPassModal'>
<div className='signUpCon'>
<div className='signUpHead'>
<span>change password</span>
<i className='fa fa-close' onClick={closeNewPass} />
</div>

<div className='signUpBody'>
<input type='hidden' className='fnewPassVal' name='forPass_Ma' id='forPass_Ma' />
<div className='signUpData'>
<label>new password *</label>
<input type='text' className='fnewPassVal' name='forPass_new' id='forPass_new' placeholder='Enter new password...' />
</div>

<div className='signUpData'>
<label>confirm password *</label>
<input type='text' className='fnewPassVal' name='forPass_conf' id='forPass_conf' placeholder='Enter confirm password...' />
</div>

<div className='signUpConf signUpBtnD'>
<button onClick={chnPassWay}>submit</button>
<button onClick={closeNewPass}>cancel</button>
</div>
</div>

</div>
</div>
</>
)
}

export default NewPass;
