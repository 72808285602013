import React from 'react';
import $ from 'jquery';

const TeenRules = () => {

const closeTeenRules = () => {
$('#teenModal').hide();
}

return (
<>
<div className='rulesModal' id='teenModal'>
<div className='rulesModCon'>
<div className='rulesHeader'>
<span id='rulesHedTxt'>teenpatti rules</span>
<i className='fa fa-close' onClick={closeTeenRules}></i>
</div>
<div className='rulesBody'>
<div className='rulesImgDiv'>
<img src='/images/teen_testrules.jpg' alt='teen_testrules' />
</div>
</div>
</div>
</div>
</>
)
}

export default TeenRules;
