import axios from 'axios';
import $ from 'jquery';

var start_dt202;

async function check_dt202(){
const getData = await axios.get('/server/active-customer');
const resData = await getData.data;
//console.log(resData);
if(resData.length > 0){
start_dt202 = setInterval(fetch_dt202, 1000);
}
else{
console.log('login error');
}
}

async function fetch_dt202(){
try {
const getData = await axios.get('/server/dt202_Data');
const resData = await getData.data;
//console.log('refresh');
if(resData.data === undefined){
//$('.odds_cas').attr('disabled', 'disabled');
clearInterval(start_dt202);
setTimeout(function(){
start_dt202 = setInterval(fetch_dt202, 1000);
},1000);
}
else{
if(resData.data.t1[0].mid === '0'){
$('.dtPls202').text('0').css({color: 'black'});
$('#allMarket_bets').hide();
}
$('#autoTime_dt202').text(resData.data.t1[0].autotime);
$('#roundId_dt202').text(resData.data.t1[0].mid);
$('#eventid_dt202').text(resData.data.t1[0].mid);

$('#card1_dt202').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c1.toUpperCase()+'.jpg');
$('#card2_dt202').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c2.toUpperCase()+'.jpg');

resData.data.t2.forEach(element => {
if(element.gstatus === '0'){
$('#dt202_Team_'+element.sid).attr('disabled','disabled');
$('#dt202_TeamTx2_'+element.sid).show().html('<i class="fa fa-lock"></i>');
}
else if(element.gstatus !== '0'){
$('#dt202_Team_'+element.sid).removeAttr('disabled');
$('#dt202_TeamTx2_'+element.sid).hide().html('');
}
});
}
} catch (error) {
clearInterval(start_dt202);
setTimeout(function(){
start_dt202 = setInterval(fetch_dt202, 1000);
},1000);
console.log('frontend refresh_dt202_data error : ' + error);
}
}

export {
check_dt202,
start_dt202,
};