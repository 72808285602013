import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import SideMenu from '../extra/SideMenu';
import { GlobalContext } from '../context/AppContext';

const customerData = '/server/active-customer';

const MyProfile = () => {

const { customer, GetUserList } = GlobalContext();

const updateProfile = async() => {
var id = customer.length > 0 ? customer[0].id : null;
var prf_name = $('#prf_name').val().trim().toLowerCase();
var prf_mob = $('#prf_mob').val().trim();
var prf_mail = $('#prf_mail').val().trim().toLowerCase();

if(prf_name === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('your name required');
setTimeout(() => {
$('#msgAlert').hide();   
},1000);
return false;
}
if(prf_mob.length < 10 || prf_mob.length > 10){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('10 digit mobile no. required');
setTimeout(() => {
$('#msgAlert').hide();   
},1000);
return false;
}
if(prf_mail === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('mail id required');
setTimeout(() => {
$('#msgAlert').hide();   
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server/update_profiles', {
id: id,
prf_name: prf_name,
prf_mob: prf_mob,
prf_mail: prf_mail
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('profile updated successfully');
setTimeout(() => {
$('#msgAlert').hide();  
GetUserList(customerData); 
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();   
},1000);
}
} catch (error) {
console.log('frontend update_profile error : ' + error);
}
}
}

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='text-page'>
<div className='text-page-wrap'>
<div className='multimarketdIV'><img src='/images/userMobile.png' alt='highImg' /> <span>profile setting</span></div>

{
customer.length > 0 &&
<div className='change-password'>
<div className='input-div-md'>
<label>username</label>
<input type='text' defaultValue={customer[0].customer} disabled />
</div>
<div className='input-div-md'>
<label>name *</label>
<input type='text' id='prf_name' placeholder='enter your full name' defaultValue={customer[0].name} />
</div>
<div className='input-div-md'>
<label>mobile no. *</label>
<input type='number' id='prf_mob' placeholder='enter mobile number' defaultValue={customer[0].MobileNumber} />
</div>
<div className='input-div-md'>
<label>mail id *</label>
<input type='email' id='prf_mail' placeholder='enter mail id' defaultValue={customer[0].mailid} />
</div>
<div className='submitBtns_RightDiv'>
<button className='medGreen_button' onClick={() => updateProfile()}>update profile</button>
</div>
</div>
}

</div>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default MyProfile;
