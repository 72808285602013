import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import SideMenu from '../../extra/SideMenu';
import { GlobalContext } from '../../context/AppContext';
import MarketBets from '../../allBets/MarketBets';
import { startResult_t201day, runresult_t201day } from './js/result';
import ResultMod from '../modals/ResultMod';
import { check_t201day, start_t201day } from './js/refresh';
import { runSettle_t201day, startSettle_t201day } from './js/settle';
import TeenRules from '../modals/TeenRules';
import {
oddsClick_t201day,
cancelBet_t201day,
minusAmt_t201day,
plusAmt_t201day,
changeAmt_t201day,
stakeBet_t201day,
betSend_t201day,
pls_t201day
} from './js/betting';

const T20OneDay = () => {

const [load_t201day, setLoadT201Day] = useState(true);
const [curr_t201day, setCurrT201day] = useState([]);
const { customer } = GlobalContext();

const openteen1DayRules = () => {
$('#teenModal').show();
}

useEffect(() => {
async function fetchData_t201day() {
try {
const getData = await axios.get('/server/teenpattiT20onedayData');
const resData = await getData.data;
if(resData.data !== undefined){
setLoadT201Day(false);
setCurrT201day(resData);
}
else{
setLoadT201Day(true);
setCurrT201day([]);
}
} catch (error) {
console.log('frontend casino_t20_oneday_data error : ' + error);
}
}

fetchData_t201day();
},[]);

useEffect(() =>{
check_t201day();
runresult_t201day();
startSettle_t201day();
pls_t201day(curr_t201day.data !== undefined ? curr_t201day.data.t1[0].marketid : 0,'t20_1day');
return () => {
clearInterval(startResult_t201day);
clearInterval(start_t201day);
clearInterval(runSettle_t201day);
}
},[curr_t201day]);

if(load_t201day){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='game-body'>
<div className='game-contents'>

{
curr_t201day.data !== undefined &&
<div className='casinoGame_wrap'>
<div className='headerCasGame'>
<div className='col'>
<span className='casGameTitle'>teenpatti 1Day</span>
<span className='casGamerule' onClick={openteen1DayRules}>rules</span>
</div>
<div className='col'>
<span className='casRoundId'>mid : <span id='roundId_t201day'>{curr_t201day.data.t1[0].marketid}</span></span>
</div>
</div>

<div className='videoWrapCas'>
<div className='liveCards'>
<div className='col'>
<span className='cardTeam'>player a</span>
<div className='teamLiveCards'>
<img id='card1_t201day' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t201day.data.t1[0].c1.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card2_t201day' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t201day.data.t1[0].c2.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card3_t201day' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t201day.data.t1[0].c3.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div> 
<div className='col'>
<span className='cardTeam'>player b</span>
<div className='teamLiveCards'>
<img id='card4_t201day' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t201day.data.t1[1].c1.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card5_t201day' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t201day.data.t1[1].c2.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card6_t201day' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t201day.data.t1[1].c3.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div>    
</div>   
<div className='countDownWrap'>
<div className='countDown'></div> 
<div className='countTxt'><span id='autoTime_t201day'>{curr_t201day.data.t1[0].lasttime}</span></div>   
</div> 
<div className='frameWrap'>
<iframe src='https://alpha-n.qnsports.live/route/ash.php?id=3031' title='casino video'></iframe>
</div>
</div>

<div className='casDataWrap'>
<table className='casTable'>
<thead>
<tr>
<th></th>
<th className='backTh2'><span>back</span></th>
<th className='layTh'><span>lay</span></th>
</tr>
</thead>
<tbody>
<tr>
<td>
<div className='teamTdCas'>
<span>{curr_t201day.data.t1[0].nation}</span>
<div className='plsDiv'>
<span className='plsCas' id={'plsCas_t201day_'+curr_t201day.data.t1[0].sectionid}>0.00</span>
<span className='plsCas' id={'plsCasBef_t201day_'+curr_t201day.data.t1[0].sectionid}></span>
</div>
</div>
</td>
<td className='countOdds backCasBox'>
<div className='locCasino' id={'locCasino_t201day_'+curr_t201day.data.t1[0].sectionid}><i className='fa fa-lock'></i></div>
<button className='odds_cas' id={'odds_casBack_t201day_'+curr_t201day.data.t1[0].sectionid} onClick={() => oddsClick_t201day(curr_t201day.data.t1[0].sectionid,'back')}>
<span className='casRate' id={'casRateBack_t201day_'+curr_t201day.data.t1[0].sectionid}>{curr_t201day.data.t1[0].b1}</span>
<span className='casSize' id={'casSizeBack_t201day_'+curr_t201day.data.t1[0].sectionid}>{curr_t201day.data.t1[0].bs1}</span>
</button>
</td>
<td className='countOdds layCasBox'>
<button className='odds_cas' id={'odds_casLay_t201day_'+curr_t201day.data.t1[0].sectionid} onClick={() => oddsClick_t201day(curr_t201day.data.t1[0].sectionid,'lay')}>
<span className='casRate' id={'casRateLay_t201day_'+curr_t201day.data.t1[0].sectionid}>{curr_t201day.data.t1[0].l1}</span>
<span className='casSize' id={'casSizeLay_t201day_'+curr_t201day.data.t1[0].sectionid}>{curr_t201day.data.t1[0].ls1}</span>
</button>
</td>
</tr>

<tr>
<td>
<div className='teamTdCas'>
<span>{curr_t201day.data.t1[1].nation}</span>
<div className='plsDiv'>
<span className='plsCas' id={'plsCas_t201day_'+curr_t201day.data.t1[1].sectionid}>0.00</span>
<span className='plsCas' id={'plsCasBef_t201day_'+curr_t201day.data.t1[1].sectionid}></span>
</div>
</div>
</td>
<td className='countOdds backCasBox'>
<div className='locCasino' id={'locCasino_t201day_'+curr_t201day.data.t1[1].sectionid}><i className='fa fa-lock'></i></div>
<button className='odds_cas' id={'odds_casBack_t201day_'+curr_t201day.data.t1[1].sectionid} onClick={() => oddsClick_t201day(curr_t201day.data.t1[1].sectionid,'back')}>
<span className='casRate' id={'casRateBack_t201day_'+curr_t201day.data.t1[1].sectionid}>{curr_t201day.data.t1[1].b1}</span>
<span className='casSize' id={'casSizeBack_t201day_'+curr_t201day.data.t1[1].sectionid}>{curr_t201day.data.t1[1].bs1}</span>
</button>
</td>
<td className='countOdds layCasBox'>
<button className='odds_cas' id={'odds_casLay_t201day_'+curr_t201day.data.t1[1].sectionid} onClick={() => oddsClick_t201day(curr_t201day.data.t1[1].sectionid,'lay')}>
<span className='casRate' id={'casRateLay_t201day_'+curr_t201day.data.t1[1].sectionid}>{curr_t201day.data.t1[1].l1}</span>
<span className='casSize' id={'casSizeLay_t201day_'+curr_t201day.data.t1[1].sectionid}>{curr_t201day.data.t1[1].ls1}</span>
</button>
</td>
</tr>

<tr style={{display:'none'}} id='placeBetCasino_t201day'>
<td style={{padding:'0'}} colSpan='3'>
<div className='wrapbet-row'>
<div className='bet-msgWrap' id='betMsgWrapCas_t201day'>
<div className='loaderDiv'>
<span className='dot1'></span>
<span className='dot2'></span>
<span className='loadTx'>Loading...</span>
</div>
<span id='bet_msgCas_t201day'></span>
</div>
<div className='bet-input-row'>
<span style={{display:'none'}} id='selBetType_t201day'></span>
<span style={{display:'none'}} id='sec_t201day'>bookmaker</span>
<span style={{display:'none'}} id='sport_t201day'>t20_1day</span>
<span style={{display:'none'}} id='teamCas_t201day'></span>
<span style={{display:'none'}} id='teamSid_t201day'></span>
<span style={{display:'none'}} id='userCurr_t201day'>{customer.length > 0 ? customer[0].currency : 'inr'}</span>
<span style={{display:'none'}} id='eventid_t201day'>{curr_t201day.data.t1[0].marketid}</span>

<div className='col'>
<div className='cancel'>
<button onClick={() => cancelBet_t201day()}>cancel</button>
</div>

<div>
<span>-</span>
<input type='number' id='bet_rate_t201day' placeholder='0' disabled />
<span>+</span>
</div>

<div>
<span onClick={() => minusAmt_t201day()}>-</span>
<input type='number' id='bet_amount_t201day' placeholder='0' onChange={(e) => changeAmt_t201day(e.target.value)} />
<span onClick={() => plusAmt_t201day()}>+</span>
</div>

<div className='place'>
<button onClick={() => betSend_t201day()} className='' id='betsPlace_t201day'>place bet</button>
</div>

</div>
</div>

<div className='colbtnBet' id='betColBet'>
<button onClick={() => stakeBet_t201day(customer.length > 0 ? customer[0].stake1 : 100)}>{customer.length > 0 ? customer[0].stake1 : 100}</button>
<button onClick={() => stakeBet_t201day(customer.length > 0 ? customer[0].stake2 : 200)}>{customer.length > 0 ? customer[0].stake2 : 200}</button>
<button onClick={() => stakeBet_t201day(customer.length > 0 ? customer[0].stake3 : 500)}>{customer.length > 0 ? customer[0].stake3 : 500}</button>
<button onClick={() => stakeBet_t201day(customer.length > 0 ? customer[0].stake4 : 1000)}>{customer.length > 0 ? customer[0].stake4 : 1000}</button>
<button onClick={() => stakeBet_t201day(customer.length > 0 ? customer[0].stake5 : 2000)}>{customer.length > 0 ? customer[0].stake5 : 2000}</button>
<button onClick={() => stakeBet_t201day(customer.length > 0 ? customer[0].stake6 : 5000)}>{customer.length > 0 ? customer[0].stake6 : 5000}</button>
<button onClick={() => stakeBet_t201day(customer.length > 0 ? customer[0].stake7 : 10000)}>{customer.length > 0 ? customer[0].stake7 : 10000}</button>
<button onClick={() => stakeBet_t201day(customer.length > 0 ? customer[0].stake8 : 25000)}>{customer.length > 0 ? customer[0].stake8 : 25000}</button>
</div>

<div className='mobilePlace'>
<div className='cancel'>
<button onClick={() => cancelBet_t201day()}>cancel</button>
</div>

<div className='place'>
<button onClick={() => betSend_t201day()} className='' id='betsPlace2_t201day'>place bet</button>
</div> 
</div>
</div>
</td>
</tr>

</tbody>
</table>
</div>

<div className='casinoResWrap'>
<div className='casinoResTitle'><span>last result</span></div>
<div className='allResCas' id='allResCas_t201day'></div>
</div>

<MarketBets eventid={curr_t201day.data !== undefined ? curr_t201day.data.t1[0].marketid : 0} />
</div>
}

</div>
</div>
</div>
</div>
</div>
</div>

<TeenRules />
<ResultMod />
</>
)
}

export default T20OneDay;
