import React, { useState } from 'react';
import $ from 'jquery';
import axios from 'axios';

const ForgotPass = () => {

const [passOtp, setPassOtp] = useState(null);

const closeForPass = () => {
$('#forPassModal').hide();
$('.forPassVal').val(''); 
$('#vrfpO1').show();
$('#sndPassOtp').removeClass('disabled').removeAttr('disabled').show();
$('#vrfpO2').hide();
$('#vrfPassOtp').hide();
}

const chnPassOtp = async() => {
var mailId = $('#forPass_gmail').val();

if(mailId === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('mail id required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
else{
$('#sndPassOtp').addClass('disabled').attr('disabled', 'disabled');
try {
const sendData = await axios.post('/server/changePassOtp', {mailId: mailId});
const resData = await sendData.data;
if(resData.split('_')[0] === 'success'){
setPassOtp(resData.split('_')[1]);
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('otp sent to your mail successfully');
setTimeout(() => {
$('#msgAlert').hide();
$('#vrfpO1').hide();
$('#sndPassOtp').removeClass('disabled').removeAttr('disabled').hide();
$('#vrfpO2').show();
$('#vrfPassOtp').show();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
$('#sndPassOtp').removeClass('disabled').removeAttr('disabled');
},1000);
}
} catch (error) {
console.log('frontend changePaass_otp_send error : ' + error);  
}
}
}

const vrfPassOtp = () => {
var forPass_vrfOtp = $('#forPass_vrfOtp').val();
var forPass_gmail = $('#forPass_gmail').val();

if(forPass_vrfOtp === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('otp required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;   
}
if(passOtp !== forPass_vrfOtp){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('enter otp not match');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;  
}
else{
$('.forPassVal').val(''); 
$('#forPassModal').hide();
$('#newPassModal').show();
$('#forPass_Ma').val(forPass_gmail);
}
}

return (
<>
<div className='signUpModal' id='forPassModal'>
<div className='signUpCon'>
<div className='signUpHead'>
<span>forgot password</span>
<i className='fa fa-close' onClick={closeForPass} />
</div>

<div className='signUpBody'>
<div className='signUpData' id='vrfpO1'>
<label>registered mail id. *</label>
<input type='email' className='forPassVal' name='forPass_gmail' id='forPass_gmail' placeholder='Enter mail account...' />
</div>

<div className='signUpData' id='vrfpO2' style={{display: 'none'}}>
<label>enter otp *</label>
<input type='text' className='forPassVal' name='forPass_vrfOtp' id='forPass_vrfOtp' placeholder='Enter your otp...' />
</div>

<div className='signUpConf signUpBtnD'>
<button id='sndPassOtp' onClick={chnPassOtp}>send OTP</button>
<button style={{display: 'none'}} id='vrfPassOtp' onClick={vrfPassOtp}>verify OTP</button>
<button onClick={closeForPass}>cancel</button>
</div>
</div>

</div>
</div>
</>
)
}

export default ForgotPass;
