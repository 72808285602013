import $ from 'jquery';
import axios from 'axios';
import { getAllBetsMarket } from '../../../allBets/Betsmarket';

let htCount = 0;
var startTimer;
var startFlip;
var headTime1;
var headTime2;
let audioPlaHead = new Audio('/audio/coin-flip.mp3');

function setMarketId(){
let marketId = '12.' + Math.floor((Math.random() * 1000000 * Date.now()).toString().slice(0, 10));
//let eventId =  Math.floor((Math.random() * 1000000 * Date.now()).toString().slice(0, 10));
$('#marketid').text(marketId);
$('#eventid_head').text(marketId);
}

function runTimerNow(){
htCount = 16;
setTimeout(() => {
startTimer = setInterval(goTimer, 1000); 
},1000); 
}

function goTimer(){
if (htCount <= 0){
$('#countstart').html(htCount);
clearInterval(startTimer);
startFlip = setTimeout(flipCoin, 1000);
} else{
htCount--;
$('#countstart').html(htCount);
if(htCount <= 3){
$('.lockhead').css({display: 'flex'});
$('.head_oddsBtn').attr('disabled', 'disabled');
cancelBet_head();
}
}
}

function flipCoin(){
let coins = document.querySelector('.coins');
var mid = $('#marketid').text();
var result = null;
let i = Math.floor(Math.random() * 2);
if(coins !== null){
if(i === 1){
setTimeout(() => {
coins.style.animation = 'spin-heads 2s forwards';
playSound(true);
result = 'h';
headTailResult(mid,result);
},1000);
}
else{
setTimeout(() => {
coins.style.animation = 'spin-tails 2s forwards';
playSound(true);
result = 't';
headTailResult(mid,result);
},1000);
}

headTime1 = setTimeout(() => {
$('#marketid').text('0');
coins.style.animation = 'none';
$('#allMarket_bets').hide();
},7000);

headTime2 = setTimeout(() => {
setMarketId();
htCount = 16;
$('.lockhead').css({display: 'none'});
$('.head_oddsBtn').removeAttr('disabled');
startTimer = setInterval(goTimer, 1000);  
settle_head();
resultHeadTail();
},10000);

}
}

function playSound(val){
if(val === true){
audioPlaHead.play();
}
else{
audioPlaHead.pause();
}
}

async function headTailResult(mid,result) {
if(mid !== '0' && result !== null){
//console.log(mid +' -- '+ result);
try {
const sendData = await axios.post('/server/saveResult_headTail/', {
mid: mid,
winner: result
});
const resData = await sendData.data;
if(resData === 'success'){
//console.log(resData);
}
else{
//console.log(resData);  
}
} catch (error) {
console.log('frontend save_result_v_headTail error : ' + error);
}
} 
}

function headOddsClick(team){
$('#bet_amount_head').val(0);
$('#teamCas_head').text(team);
$('#placeBetCasino_head').show();
$('#betsPlace_head').removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_head').removeClass('active').attr('disabled', 'disabled');
}

function cancelBet_head(){
$('#placeBetCasino_head').hide();
$('#teamCas_head').text('');
}

function stakeBet_head(amt){
$('#bet_amount_head').val(amt);
$('#betsPlace_head').addClass('active').removeAttr('disabled');
$('#betsPlace2_head').addClass('active').removeAttr('disabled');
}

function betSend_head(){
$('#betMsgWrapCas_head').css({display: 'grid'});
setTimeout(function(){
finalPlaceBet_head();
},1000);
}

async function finalPlaceBet_head(){
var selBetType = $('#selBetType_head').text();
var sec = $('#sec_head').text();
var sport = $('#sport_head').text();
var eventname = 'virtual casino';
var teamCas = $('#teamCas_head').text();
var bet_rate = '1.98';
var bet_amount = $('#bet_amount_head').val();
var eventid = $('#eventid_head').text();
var userCurr = $('#userCurr_head').text();
var betdelay = 1;
let sid = $('#teamSid_head').text();

if(teamCas === ''){
$('#betMsgWrapCas_head').css({display: 'none'});
cancelBet_head(); 
return false;
}

let minBet = 0;
if(userCurr === 'inr'){
minBet = 100;
}
else{
minBet = 1;  
}

if(eventid === '0'){
$('#bet_msgCas_head').show().html('roundid changed');
setTimeout(function(){
$('#betMsgWrapCas_head').css({display: 'none'});
$('#bet_msgCas_head').hide().html('');
},1000);
return false;
}
if(bet_amount === '' || bet_amount < minBet){
$('#bet_msgCas_head').show().html('minimum betamount ' + minBet);
setTimeout(function(){
$('#betMsgWrapCas_head').css({display: 'none'});
$('#bet_msgCas_head').hide().html('');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server/casino_saveBet', {
selBetType: selBetType,
sec: sec,
sport: sport,
eventname: eventname,
teamCas: teamCas,
bet_rate: bet_rate,
bet_amount: bet_amount,
eventid: eventid,
betdelay: betdelay,
sid: sid
});
const resData = await sendData.data;
if(resData === 'success'){
setExposure();
getAllBetsMarket(eventid);
$('#bet_msgCas_head').show().html('bet placed');
setTimeout(function(){
$('#betMsgWrapCas_head').css({display: 'none'});
$('#bet_msgCas_head').hide().html('');
cancelBet_head();
},1000);
return false;
}
else{
$('#bet_msgCas_head').show().html(resData);
setTimeout(function(){
$('#betMsgWrapCas_head').css({display: 'none'});
$('#bet_msgCas_head').hide().html('');
cancelBet_head();
},1000);
}
} catch (error) {
console.log('frontend head_send_savebet data : ' + error);
}
}
}

async function setExposure(){
let currBal = 0;
let currExpo = 0;
const getBal = await axios.get('/server/active-customer');
const balRes = await getBal.data;
if(balRes.length > 0){
currBal = balRes[0].total_balance;
}

const getExpo = await axios.get('/server/user-exposure');
const expoRes = await getExpo.data;
if(expoRes.length > 0){
if(expoRes[0].exposure !== null){
currExpo = expoRes[0].exposure;
}
}

$('#mainBal').html(parseFloat((currBal - currExpo).toFixed(2)).toLocaleString());
$('#mainExpo').html(parseFloat((currExpo).toFixed(2)).toLocaleString());
//console.log('curr balance : '+currBal +' exposure : '+ currExpo);

}

async function settle_head(){
try {
const getData = await axios.get('/server/head_settle');
const resData = await getData.data;
if(resData === 'success'){
//console.log('settle head clear'); 
setExposure();
}
else{
//console.log(resData);  
}
} catch (error) {
console.log('frontend head settle error : ' + error);
}
}

async function resultHeadTail() {
try {
const getData = await axios.get('/server/head_result');
const resData = await getData.data;
//console.log(resData);
if(resData.length > 0){
document.getElementById('resultHead').innerHTML = '';
resData.forEach(element => {
var winnerFirst = element.winner;
document.getElementById('resultHead').innerHTML += `<span class='activeReddark'>${winnerFirst}</span>`;
});
}
} catch (error) {
console.log('frontend head_result error : ' + error);
}  
}

export {
setMarketId,
runTimerNow,
startTimer,
startFlip,
headOddsClick,
cancelBet_head,
stakeBet_head,
betSend_head,
resultHeadTail,
settle_head,
headTime1,
headTime2,
playSound
}