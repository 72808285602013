import axios from 'axios';
import $ from 'jquery';

var start_t201day;

async function check_t201day(){
const getData = await axios.get('/server/active-customer');
const resData = await getData.data;
//console.log(resData);
if(resData.length > 0){
start_t201day = setInterval(fetchData_t201day, 1000);
}
else{
console.log('login error');
}
}

async function fetchData_t201day(){
try {
const getData = await axios.get('/server/teenpattiT20onedayData');
const resData = await getData.data;
//console.log('refresh');
if(resData.data === undefined){
clearInterval(start_t201day);
setTimeout(function(){
start_t201day = setInterval(fetchData_t201day, 1000);
},1000);
}
else{
var currMid = $('#roundId_t201day').text();
if(currMid !== resData.data.t1[0].marketid){
$('#plsCas_t201day_1').text('0.00').css({color: 'black'});
$('#plsCas_t201day_2').text('0.00').css({color: 'black'});
$('#allMarket_bets').hide();
}
$('#autoTime_t201day').text(resData.data.t1[0].lasttime);
$('#roundId_t201day').text(resData.data.t1[0].marketid);
$('#eventid_t201day').text(resData.data.t1[0].marketid);

$('#card1_t201day').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c1.toUpperCase()+'.jpg');
$('#card2_t201day').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c2.toUpperCase()+'.jpg');
$('#card3_t201day').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c3.toUpperCase()+'.jpg');
$('#card4_t201day').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[1].c1.toUpperCase()+'.jpg');
$('#card5_t201day').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[1].c2.toUpperCase()+'.jpg');
$('#card6_t201day').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[1].c3.toUpperCase()+'.jpg');

if(resData.data.t1[0].gstatus === 'suspended'){
$('#locCasino_t201day_'+resData.data.t1[0].sectionid).css({display: 'flex'});
$('#casRateBack_t201day_'+resData.data.t1[0].sectionid).text(resData.data.t1[0].b1);
$('#casSizeBack_t201day_'+resData.data.t1[0].sectionid).text(numFormat(resData.data.t1[0].bs1));
$('#casRateLay_t201day_'+resData.data.t1[0].sectionid).text(resData.data.t1[0].l1);
$('#casSizeLay_t201day_'+resData.data.t1[0].sectionid).text(numFormat(resData.data.t1[0].ls1));
}
else if(resData.data.t1[0].gstatus !== 'suspended'){
$('#locCasino_t201day_'+resData.data.t1[0].sectionid).hide();
$('#casRateBack_t201day_'+resData.data.t1[0].sectionid).text(resData.data.t1[0].b1);
$('#casSizeBack_t201day_'+resData.data.t1[0].sectionid).text(numFormat(resData.data.t1[0].bs1));
$('#casRateLay_t201day_'+resData.data.t1[0].sectionid).text(resData.data.t1[0].l1);
$('#casSizeLay_t201day_'+resData.data.t1[0].sectionid).text(numFormat(resData.data.t1[0].ls1));
}

if(resData.data.t1[1].gstatus === 'suspended'){
$('#locCasino_t201day_'+resData.data.t1[1].sectionid).css({display: 'flex'});
$('#casRateBack_t201day_'+resData.data.t1[1].sectionid).text(resData.data.t1[1].b1);
$('#casSizeBack_t201day_'+resData.data.t1[1].sectionid).text(numFormat(resData.data.t1[1].bs1));
$('#casRateLay_t201day_'+resData.data.t1[1].sectionid).text(resData.data.t1[1].l1);
$('#casSizeLay_t201day_'+resData.data.t1[1].sectionid).text(numFormat(resData.data.t1[1].ls1));
}
else if(resData.data.t1[1].gstatus !== 'suspended'){
$('#locCasino_t201day_'+resData.data.t1[1].sectionid).hide();
$('#casRateBack_t201day_'+resData.data.t1[1].sectionid).text(resData.data.t1[1].b1);
$('#casSizeBack_t201day_'+resData.data.t1[1].sectionid).text(numFormat(resData.data.t1[1].bs1));
$('#casRateLay_t201day_'+resData.data.t1[1].sectionid).text(resData.data.t1[1].l1);
$('#casSizeLay_t201day_'+resData.data.t1[1].sectionid).text(numFormat(resData.data.t1[1].ls1));
}

}
} catch (error) {
clearInterval(start_t201day);
setTimeout(function(){
start_t201day = setInterval(fetchData_t201day, 1000);
},1000);
console.log('frontend refresh_t20_oneday_data error : ' + error);
}
}

function numFormat(number){
if (number < 1000) {
return number;
} else if (number >= 1000 && number < 1_000_000) {
return (number / 1000).toFixed(1).replace(/\.0$/, "") + "K";
} else if (number >= 1_000_000 && number < 1_000_000_000) {
return (number / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
} else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
} else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
return (number / 1_000_000_000_000).toFixed(1).replace(/\.0$/, "") + "T";
}
}

export {
check_t201day,
start_t201day,
};