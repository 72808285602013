import React from 'react';
import $ from 'jquery';

const Lu7Mod = () => {

const closeDetReslu7a = () => {
$('#casResModal_lu7a').hide();
}

return (
<>
<div className='casResModal' id='casResModal_lu7a'>
<div className='casResCont'>
<div className='casResHead'><span>details result</span> <i className='fa fa-close' onClick={closeDetReslu7a}></i></div>
<div className='casResBody'>

<div className='casDetResLoad_lu7a'>
<span></span>
</div>

<div className='resCasCon_lu7a'>
<div className='cardsRow'>
<div className='col collong'>
<span className='cardTeamCas'>card</span>
<div className='cardsImg'>
<img id='img1lu7a' alt='card_img' />
</div>
</div>
</div>

<div className='casResWin'>
<span className='casRestxt'>winner : <span id='winCaslu7a'></span></span>
<span className='casRestxt'>mid : <span id='winCasMidlu7a'></span></span>
</div>
</div>

<div className='record_no' id='record_no_lu7a'>
<img src='/images/poker_rec.png' alt='poker_rec' />   
<span>no records found...</span>
</div>

</div>
</div>
</div>
</>
)
}

export default Lu7Mod;
