import React from 'react';
import $ from 'jquery';

const DiceResult = () => {

const closeDiceRes = () => {
$('#diceResModal').hide();
$('#dice_img1').attr('src', '');
$('#dice_img2').attr('src', '');
$('#win_dice').text('');
$('#win_dice1').text('');
$('#win_dice2').text('');
$('#win_diceMid').text('');
$('.resCasConDice').hide();
}

return (
<>
<div className='casResModal' id='diceResModal'>
<div className='casResCont'>
<div className='casResHead'><span>details result</span> <i className='fa fa-close' onClick={closeDiceRes}></i></div>
<div className='casResBody'>

<div className='casDetResLoad'>
<span></span>
</div>

<div className='resCasConDice'>
<div className='cardsRow'>
<div className='col'>
<span className='cardTeamCas'>player 1</span>
<div className='cardsImg'>
<img id='dice_img1' alt='card_img' />
</div>
</div>
<div className='col'>
<span className='cardTeamCas'>player 2</span>
<div className='cardsImg'>
<img id='dice_img2' alt='card_img' />
</div>
</div>
</div>

<div className='casResWin'>
<span className='casRestxt'>winner : <span id='win_dice'></span></span>
<span className='casRestxt'>player 1 : <span id='win_dice1'></span></span>
<span className='casRestxt'>player 2 : <span id='win_dice2'></span></span>
<span className='casRestxt'>mid : <span id='win_diceMid'></span></span>
</div>
</div>

<div className='record_no' id='record_no_dice'>
<img src='/images/poker_rec.png' alt='poker_rec' />   
<span>no records found...</span>
</div>

</div>
</div>
</div>
</>
)
}

export default DiceResult;
