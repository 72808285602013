import axios from 'axios';
import $ from 'jquery';

var startResult_lu7a;

function runresult_lu7a(){
fetchResult_lu7a();
startResult_lu7a = setInterval(fetchResult_lu7a, 2000);
}

async function fetchResult_lu7a() {
try {
const getData = await axios.get('/server/lucky7a_Result');
const resData = await getData.data;
//console.log('result');
if(resData.data === undefined){
clearInterval(startResult_lu7a);
setTimeout(function(){
startResult_lu7a = setInterval(fetchResult_lu7a, 1000);
},1000);
}
else{
if(document.getElementById('allResCas_lu7a') !== null){
document.getElementById('allResCas_lu7a').innerHTML = '';
resData.data.forEach(element => {
document.getElementById('allResCas_lu7a').innerHTML += `<span id='casResFull_${element.mid}' class=${element.result === '1' ? 'dotOne_lu7a' : 'dotTwo_lu7a'}>${element.result === '1' ? 'L' : 'H'}</span>`;
});   
}
}
} catch (error) {
clearInterval(startResult_lu7a);
setTimeout(function(){
startResult_lu7a = setInterval(fetchResult_lu7a, 1000);
},1000);
console.log('frontend casino_lu7a_result error : ' + error);
}
}

$(document).on('click', '.dotOne_lu7a', function(e){
casinoResMod_lu7a(e.target.id);
$('#casResModal_lu7a').show();
$('.casDetResLoad_lu7a').css({display: 'flex'});
$('.resCasCon_lu7a').hide();
});

$(document).on('click', '.dotTwo_lu7a', function(e){
casinoResMod_lu7a(e.target.id);
$('#casResModal_lu7a').show();
$('.casDetResLoad_lu7a').css({display: 'flex'});
$('.resCasCon_lu7a').hide();
});

async function casinoResMod_lu7a(mid) {
$('#record_no_lu7a').hide();
var midSplit = mid.split('_')[1];
$('#img1lu7a').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');

try {
const getData = await axios.get('/server/casinoDetailsResult/'+midSplit);
const resData = await getData.data;
if(resData.data !== undefined){
//console.log(resData.data);
const allcard = resData.data[0].cards.toUpperCase();
const allcardSpl = allcard.split('');
const descSpl = resData.data[0].desc.split('||');
var card1set = allcardSpl[1] + allcardSpl[2];
var cardColour = '';
if(card1set === 'HH' || card1set === 'DD' || card1set === '0H' || card1set === '0D'){
cardColour = 'red';
}
else{
cardColour = 'black'; 
}

$('#img1lu7a').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard+'.jpg');
$('#winCaslu7a').text(descSpl[0] +' || '+ cardColour +' || '+ descSpl[2] +' || '+ descSpl[3]);
$('#winCasMidlu7a').text(resData.data[0].mid).show();
$('.casDetResLoad_lu7a').css({display: 'none'});
$('.resCasCon_lu7a').show();
}
else{
$('#winCasMidlu7a').hide();
$('.casDetResLoad_lu7a').hide();
$('.resCasCon_lu7a').hide();
$('#record_no_lu7a').show();
}
} catch (error) {
console.log('frontend casino_lu7a_details_result error : ' + error);
}
}

export { startResult_lu7a, runresult_lu7a, casinoResMod_lu7a };