import axios from 'axios';
import $ from 'jquery';

var startResult_teenTest;

function runresult_teenTest(){
fetchResult_teenTest();
startResult_teenTest = setInterval(fetchResult_teenTest, 2000);
}

async function fetchResult_teenTest() {
try {
const getData = await axios.get('/server/t20test_Result');
const resData = await getData.data;
//console.log('result');
if(resData.data === undefined){
clearInterval(startResult_teenTest);
setTimeout(function(){
startResult_teenTest = setInterval(fetchResult_teenTest, 1000);
},1000);
}
else{
if(document.getElementById('allResCas_teenTest') !== null){
document.getElementById('allResCas_teenTest').innerHTML = '';
resData.data.forEach(element => {
document.getElementById('allResCas_teenTest').innerHTML += `<span id='casResFull_${element.mid}' class=${element.result === '11' ? 'dotOne_teenTest' : element.result === '21' ? 'dotTwo_teenTest' : 'dotThree_teenTest'}>${element.result === '11' ? 'T' : element.result === '21' ? 'L' : 'D'}</span>`;
});   
}
}
} catch (error) {
clearInterval(startResult_teenTest);
setTimeout(function(){
startResult_teenTest = setInterval(fetchResult_teenTest, 1000);
},1000);
console.log('frontend casino_teenTest_result error : ' + error);
}
}

$(document).on('click', '.dotOne_teenTest', function(e){
casinoResMod_teenTest(e.target.id);
$('#casResModalTs').show();
$('.casDetResLoadTs').css({display: 'flex'});
$('.resCasConTs').hide();
});

$(document).on('click', '.dotTwo_teenTest', function(e){
casinoResMod_teenTest(e.target.id);
$('#casResModalTs').show();
$('.casDetResLoadTs').css({display: 'flex'});
$('.resCasConTs').hide();
});

$(document).on('click', '.dotThree_teenTest', function(e){
casinoResMod_teenTest(e.target.id);
$('#casResModalTs').show();
$('.casDetResLoadTs').css({display: 'flex'});
$('.resCasConTs').hide();
});

async function casinoResMod_teenTest(mid) {
$('#record_no_teenTest').hide();
var midSplit = mid.split('_')[1];
$('#img1Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');
$('#img2Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');
$('#img3Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');
$('#img4Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');
$('#img5Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');
$('#img6Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');
$('#img7Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');
$('#img8Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');
$('#img9Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');
try {
const getData = await axios.get('/server/casinoDetailsResult/'+midSplit);
const resData = await getData.data;
if(resData.data !== undefined){
//console.log(resData.data);
const allcard = resData.data[0].cards.toUpperCase().split(',');
//console.log(allcard);
$('#img1Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard[0]+'.jpg');
$('#img2Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard[3]+'.jpg');
$('#img3Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard[6]+'.jpg');

$('#img4Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard[1]+'.jpg');
$('#img5Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard[4]+'.jpg');
$('#img6Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard[7]+'.jpg');

$('#img7Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard[2]+'.jpg');
$('#img8Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard[5]+'.jpg');
$('#img9Ts').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard[8]+'.jpg');
$('#winCasTs').text(resData.data[0].win === '11' ? 'tiger' : resData.data[0].win === '21' ? 'lion' : 'dragon');
$('#winCasMidTs').text(resData.data[0].mid).show();
$('.casDetResLoadTs').css({display: 'none'});
$('.resCasConTs').show();
}
else{
$('#winCasMidTs').hide();
$('.casDetResLoadTs').hide();
$('.resCasConTs').hide();
$('#record_no_teenTest').show();
}
} catch (error) {
console.log('frontend casino_teenTest_details_result error : ' + error);
}
}

export { startResult_teenTest, runresult_teenTest, casinoResMod_teenTest };