import React from 'react';
import $ from 'jquery';

const T20Rules = () => {

const closet20Rules = () => {
$('#t20Modal').hide();
}

return (
<>
<div className='rulesModal' id='t20Modal'>
<div className='rulesModCon'>
<div className='rulesHeader'>
<span id='rulesHedTxt'>t20 rules</span>
<i className='fa fa-close' onClick={closet20Rules}></i>
</div>
<div className='rulesBody'>
<div className='rulesImgDiv'>
<img src='/images/t20rules.jpg' alt='t20rules' />
</div>
</div>
</div>
</div>
</>
)
}

export default T20Rules;
