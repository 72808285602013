import React from 'react';
import $ from 'jquery';

const DtRules = () => {

const closeDtRules = () => {
$('#dtModal').hide();
}

return (
<>
<div className='rulesModal' id='dtModal'>
<div className='rulesModCon'>
<div className='rulesHeader'>
<span id='rulesHedTxt'>dragon tiger rules</span>
<i className='fa fa-close' onClick={closeDtRules}></i>
</div>
<div className='rulesBody'>
<div className='rulesImgDiv'>
<img src='/images/dt20rules.jpg' alt='dt20rules' />
</div>
</div>
</div>
</div>
</>
)
}

export default DtRules;
