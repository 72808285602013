import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import SideMenu from '../extra/SideMenu';
import Deposit from './Deposit';
import Withdraw from './Withdraw';
import Transation from './Transation';
import { useLocation } from 'react-router-dom';

const Banking = () => {

const [bankPage, setBankPage] = useState('deposit');
const location = useLocation();

useEffect(() => {
$(document).on('click', '.baniking_option button', function(){
$(this).addClass('active').siblings().removeClass('active');
});

if(location.state !== null){
if(location.state.type !== 'deposit'){
$('.baniking_option button:nth-child(1)').removeClass('active');
$('.baniking_option button:nth-child(2)').addClass('active');
}
setBankPage(location.state.type);
}
},[location]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='text-page'>
<div className='text-page-wrap'>
<div className='multimarketdIV'><img src='/images/bank-building.png' alt='highImg' /> <span>banking</span></div>

<div className='bankingPage'>
<div className='baniking_option'>
<button className='active' onClick={() => setBankPage('deposit')}>deposit</button>
<button onClick={() => setBankPage('withdraw')}>withdraw</button>
<button onClick={() => setBankPage('transation')}>transation</button>
</div>

<div className='bankingData'>
{
bankPage === 'deposit' ? <Deposit /> : bankPage === 'withdraw' ? <Withdraw /> : <Transation />
}
</div>

</div>

</div>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default Banking;
