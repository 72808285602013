import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AutoScroll = () => {
const { pathname } = useLocation();
useEffect(() => {
document.body.scrollTo({ top: 0, behavior: 'smooth' });
document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
},[pathname]);
return null;
}

export default AutoScroll;