import $ from 'jquery';
import axios from 'axios';
import { getAllBetsMarket } from '../../../../allBets/Betsmarket';

let dice1count = 0;
let marketId = 0;
var startCouAutdice1;
var spinRunDice1;
var dice1Time;
var dice2Time;
var dice3Time;
let audioPlaDice = new Audio('/audio/rolling-dice.mp3');

function dice1Mid(){
marketId = '13.' + Math.floor((Math.random() * 1500000 * Date.now()).toString().slice(0, 10));
//eventId =  Math.floor((Math.random() * 1300000 * Date.now()).toString().slice(0, 10));
$('#marketid_dice1').text(marketId);
$('#eventid_dice1').text(marketId);
$('#eventid_dice2').text(marketId);
$('#eventid_dice3').text(marketId);
$('#eventid_dice4').text(marketId);
}

function runTimerDice1(){
dice1count = 16;
$('.lockRollDice').css({display: 'none'});
$('.rollDiceBtn').removeAttr('disabled');
setTimeout(() => {
startCouAutdice1 = setInterval(countDice1, 1000);  
}, 1000);
}

function countDice1(){
if(dice1count <= 0){
$('#countstart_dice1').text(dice1count);
clearInterval(startCouAutdice1);
spinRunDice1 = setTimeout(rollDice, 3000);
return false;
}
else{
dice1count--;
if(dice1count <= 3){
$('.lockRollDice').css({display: 'flex'});
$('.rollDiceBtn').attr('disabled', 'disabled');
$('.placeBetCasino').hide();
}
$('#countstart_dice1').text(dice1count);
}
}

function rollDice() {
const elDiceOne = document.getElementById('dice1');
const elDiceTwo = document.getElementById('dice2');
if(elDiceOne !== null && elDiceTwo !== null){
let diceOne = Math.floor((Math.random() * 6) + 1);
let diceTwo = Math.floor((Math.random() * 6) + 1);
let dice1Win = 0;
let dice2Win = 0;
var winner = null;
let mid = $('#marketid_dice1').text();
//console.log(diceOne +' -- '+ diceTwo);
playSoundDice(true);

for (var i = 1; i <= 6; i++) {
elDiceOne.classList.remove('show-' + i);
if (diceOne === i) {
elDiceOne.classList.add('show-' + i);
}
}

for (var k = 1; k <= 6; k++) {
elDiceTwo.classList.remove('show-' + k);
if (diceTwo === k) {
elDiceTwo.classList.add('show-' + k);
}
}

if(diceOne){
if(diceOne === 6){
dice1Win = 2;
}
else if(diceOne === 5){
dice1Win = 4;
}
else if(diceOne === 4){
dice1Win = 3;
}
else if(diceOne === 3){
dice1Win = 6;
}
else if(diceOne === 1){
dice1Win = 1;
}
else if(diceOne === 2){
dice1Win = 5;
}
}

if(diceTwo){
if(diceTwo === 6){
dice2Win = 2;
}
else if(diceTwo === 5){
dice2Win = 4;
}
else if(diceTwo === 4){
dice2Win = 3;
}
else if(diceTwo === 3){
dice2Win = 6;
}
else if(diceTwo === 1){
dice2Win = 1;
}
else if(diceTwo === 2){
dice2Win = 5;
}
}

//console.log(dice1Win +' -- '+ dice2Win);

if(dice1Win > dice2Win){
let oddEven = null;
let oddEven2 = null;
if(dice1Win === 1 || dice1Win === 3 || dice1Win === 5){
oddEven = 'odd';
}
else{
oddEven = 'even';
}

if(dice2Win === 1 || dice2Win === 3 || dice2Win === 5){
oddEven2 = 'odd';
}
else{
oddEven2 = 'even';
}
winner = 'player 1_'+dice1Win +'_'+ oddEven +'_'+ dice2Win +'_'+ oddEven2;
}
else if(dice2Win > dice1Win){
let oddEven = null;
let oddEven2 = null;
if(dice1Win === 1 || dice1Win === 3 || dice1Win === 5){
oddEven = 'odd';
}
else{
oddEven = 'even';
}

if(dice2Win === 1 || dice2Win === 3 || dice2Win === 5){
oddEven2 = 'odd';
}
else{
oddEven2 = 'even';
}
winner = 'player 2_'+dice1Win +'_'+ oddEven +'_'+ dice2Win +'_'+ oddEven2;
}
else{
let oddEven = null;
let oddEven2 = null;
if(dice1Win === 1 || dice1Win === 3 || dice1Win === 5){
oddEven = 'odd';
}
else{
oddEven = 'even';
}

if(dice2Win === 1 || dice2Win === 3 || dice2Win === 5){
oddEven2 = 'odd';
}
else{
oddEven2 = 'even';
}
winner = 'draw_'+dice1Win +'_'+ oddEven +'_'+ dice2Win +'_'+ oddEven2;
}

dice1Time = setTimeout(() => {
$('.winTitSec').css({display: 'inline-flex'});
$('#winRollDice').text(winner.split('_')[0]);
saveResult_rollDice(mid,winner);
}, 1500);

dice2Time = setTimeout(() => {
$('#marketid_dice1').text(0);
$('#eventid_dice1').text(0);
$('.winTitSec').css({display: 'none'});
$('#allMarket_bets').hide();
}, 5000);

dice3Time = setTimeout(() => {
dice1Mid();
runTimerDice1();
getResult_rollDice();
settle_rollDice();
}, 8000);

//console.log(winner);
}
}

function playSoundDice(val){
if(val === true){
audioPlaDice.play();
}
else{
audioPlaDice.pause();
}
}

async function saveResult_rollDice(mid,winner){
if(mid !== '0' && winner !== null){
try {
const sendData = await axios.post('/server/saveResult_rollDice', {
mid: mid,
winner: winner
});
const resData = await sendData.data;
if(resData === 'success'){
//console.log(resData);
}
else{
//console.log(resData);
}
} catch (error) {
console.log('frontend saveResult_rollDice error : ' + error);
}
}
}

async function getResult_rollDice(){
if(document.getElementById('resultRollDice') !== null){
try {
const getData = await axios.get('/server/rollDice_result');
const resData = await getData.data;
//console.log(resData);
if(resData.length > 0){
document.getElementById('resultRollDice').innerHTML = '';
resData.forEach(element => {
var winnerFirst = element.winner.split('_')[0].split(' ')[1];
document.getElementById('resultRollDice').innerHTML += `<span class='activeReddark rollDiceDot' id=${element.id}>${winnerFirst !== undefined ? winnerFirst : 'd'}</span>`;
});
}
} catch (error) {
console.log('frontend roll_dice_result error : ' + error);
}  
}
}

$(document).on('click', '.rollDiceDot', async function(e){
//console.log('id : ' + e.target.id);
$('#record_no_dice').hide();
$('#diceResModal').show();
$('.casDetResLoad').css({display: 'flex'});
try {
const getData = await axios.get('/server/rollDice_result');
const resData = await getData.data;
if(resData.length > 0){
const filterData = resData.filter((ex) => parseInt(ex.id) === parseInt(e.target.id));
//console.log(filterData);
if(filterData.length > 0){
$('.casDetResLoad').hide();
$('.resCasConDice').show();
var winner = filterData[0].winner.split('_');

if(winner[1]){
if(winner[1] === '1'){
$('#dice_img1').attr('src', '/images/dice/dice1.png');
}
else if(winner[1] === '2'){
$('#dice_img1').attr('src', '/images/dice/dice2.png');
}
else if(winner[1] === '3'){
$('#dice_img1').attr('src', '/images/dice/dice3.png');
}
else if(winner[1] === '4'){
$('#dice_img1').attr('src', '/images/dice/dice4.png');
}
else if(winner[1] === '5'){
$('#dice_img1').attr('src', '/images/dice/dice5.png');
}
else if(winner[1] === '6'){
$('#dice_img1').attr('src', '/images/dice/dice6.png');
}
}

if(winner[3]){
if(winner[3] === '1'){
$('#dice_img2').attr('src', '/images/dice/dice1.png');
}
else if(winner[3] === '2'){
$('#dice_img2').attr('src', '/images/dice/dice2.png');
}
else if(winner[3] === '3'){
$('#dice_img2').attr('src', '/images/dice/dice3.png');
}
else if(winner[3] === '4'){
$('#dice_img2').attr('src', '/images/dice/dice4.png');
}
else if(winner[3] === '5'){
$('#dice_img2').attr('src', '/images/dice/dice5.png');
}
else if(winner[3] === '6'){
$('#dice_img2').attr('src', '/images/dice/dice6.png');
}
}

$('#win_dice').text(winner[0]);
$('#win_dice1').text('dice '+ winner[1] +' || dice '+ winner[2]);
$('#win_dice2').text('dice '+ winner[3] +' || dice '+ winner[4]);
$('#win_diceMid').text(filterData[0].mid);
}
}
else{
$('#record_no_dice').show();
}
} catch (error) {
console.log('frontend display_rollDice_result error : ' + error);
}
});

async function settle_rollDice(){
try {
const getData = await axios.get('/server/rollDice_settle');
const resData = await getData.data;
if(resData === 'success'){
//console.log('settle roll_dice clear'); 
setExposure();
}
else{
//console.log(resData);  
}
} catch (error) {
console.log('frontend roll_dice settle error : ' + error);
}
}

function rollDiceClick(id,team){
$('#bet_amount_rollDice'+id).val(0);
$('#teamCas_rollDice'+id).text(team);
$('#betsPlace_rollDice'+id).removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_rollDice'+id).removeClass('active').attr('disabled', 'disabled');
for(let i = 0; i <= 4; i++){
if(i === id){
$('#placeBetCasino_rollDice'+i).show();
}
else{
$('#placeBetCasino_rollDice'+i).hide();
}
}
}

function cancelBet_rollDice(id){
$('#placeBetCasino_rollDice'+id).hide();
}

function stakeBet_rollDice(id,amt){
$('#bet_amount_rollDice'+id).val(amt);
$('#betsPlace_rollDice'+id).addClass('active').removeAttr('disabled');
$('#betsPlace2_rollDice'+id).addClass('active').removeAttr('disabled');  
}

function betSend_rollDice(id){
$('#betMsgWrapCas_rollDice'+id).css({display: 'grid'});
setTimeout(function(){
finalPlaceBet_rollDice(id);
},1000);
}

async function finalPlaceBet_rollDice(id){
var selBetType = $('#selBetType_rollDice'+id).text();
var sec = $('#sec_rollDice'+id).text();
var sport = $('#sport_rollDice'+id).text();
var eventname = 'virtual casino';
var teamCas = $('#teamCas_rollDice'+id).text();
var bet_rate = '1.98';
var bet_amount = $('#bet_amount_rollDice'+id).val();
var eventid = $('#eventid_dice'+id).text();
var userCurr = $('#userCurr_rollDice'+id).text();
var betdelay = 1;
let sid = $('#teamSid_rollDice'+id).text();

if(teamCas === ''){
$('#betMsgWrapCas_rollDice'+id).css({display: 'none'});
cancelBet_rollDice(id); 
return false;
}

let minBet = 0;
if(userCurr === 'inr'){
minBet = 100;
}
else{
minBet = 1;  
}

if(eventid === '0'){
$('#bet_msgCas_rollDice'+id).show().html('roundid changed');
setTimeout(function(){
$('#betMsgWrapCas_rollDice'+id).css({display: 'none'});
$('#bet_msgCas_rollDice'+id).hide().html('');
},1000);
return false;
}
if(bet_amount === '' || bet_amount < minBet){
$('#bet_msgCas_rollDice'+id).show().html('minimum betamount ' + minBet);
setTimeout(function(){
$('#betMsgWrapCas_rollDice'+id).css({display: 'none'});
$('#bet_msgCas_rollDice'+id).hide().html('');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server/casino_saveBet', {
selBetType: selBetType,
sec: sec,
sport: sport,
eventname: eventname,
teamCas: teamCas,
bet_rate: bet_rate,
bet_amount: bet_amount,
eventid: eventid,
betdelay: betdelay,
sid: sid
});
const resData = await sendData.data;
if(resData === 'success'){
setExposure();
getAllBetsMarket(eventid);
$('#bet_msgCas_rollDice'+id).show().html('bet placed');
setTimeout(function(){
$('#betMsgWrapCas_rollDice'+id).css({display: 'none'});
$('#bet_msgCas_rollDice'+id).hide().html('');
cancelBet_rollDice(id);
},1000);
return false;
}
else{
$('#bet_msgCas_rollDice'+id).show().html(resData);
setTimeout(function(){
$('#betMsgWrapCas_rollDice'+id).css({display: 'none'});
$('#bet_msgCas_rollDice'+id).hide().html('');
cancelBet_rollDice(id);
},1000);
}
} catch (error) {
console.log('frontend rollDice_send_savebet data : ' + error);
}
}
}

async function setExposure(){
let currBal = 0;
let currExpo = 0;
const getBal = await axios.get('/server/active-customer');
const balRes = await getBal.data;
if(balRes.length > 0){
currBal = balRes[0].total_balance;
}

const getExpo = await axios.get('/server/user-exposure');
const expoRes = await getExpo.data;
if(expoRes.length > 0){
if(expoRes[0].exposure !== null){
currExpo = expoRes[0].exposure;
}
}

$('#mainBal').html(parseFloat((currBal - currExpo).toFixed(2)).toLocaleString());
$('#mainExpo').html(parseFloat((currExpo).toFixed(2)).toLocaleString());
//console.log('curr balance : '+currBal +' exposure : '+ currExpo);

}


export { 
dice1Mid,
runTimerDice1,
startCouAutdice1,
spinRunDice1,
getResult_rollDice,
dice1Time,
dice2Time,
dice3Time,
settle_rollDice,
rollDiceClick,
cancelBet_rollDice,
stakeBet_rollDice,
betSend_rollDice,
playSoundDice
};