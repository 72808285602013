import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { reject } from '../extra/rejectCasino';
import { GlobalContext } from '../context/AppContext';

const AcceptAge = () => {

const { userIp } = GlobalContext();

const confirmLogin = async() => {
const customer = $('#clientUserModal').val();
const password = $('#clientPassModal').val();
const user_ip = userIp.length !== 0 ? userIp.ip : null;
const city = userIp.length !== 0 ? userIp.city : null;
const region = userIp.length !== 0 ? userIp.region : null;
const country = userIp.length !== 0 ? userIp.country : null;
const org = userIp.length !== 0 ? userIp.org : null;
const postal = userIp.length !== 0 ? userIp.postal : null;
const timezone = userIp.length !== 0 ? userIp.timezone : null;
$('#confloginBtn').attr('disabled', 'disabled').html('<img src="/images/loadingBtn.png" alt="loadingBtn" />');

try {
const sendData = await axios.post('/server/login', {
customer: customer, 
password: password,
user_ip: user_ip,
city: city,
region: region,
country: country,
org: org,
postal: postal,
timezone: timezone
});
const resData = await sendData.data;
if(resData === 'success'){
$("#msgAlert").fadeIn().addClass('alert-success').removeClass('alert-danger').html('login successfull');
setTimeout(function(){
$("#msgAlert").hide();
reject();
window.location.reload();
},1000);
return false;   
}
else{
$("#msgAlert").fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(function(){
$("#msgAlert").hide();
$('#confloginBtn').removeAttr('disabled').html('confirm');
$('#AcceptAgeModal').hide();
},1000);
}
} catch (error) {
console.log('frontend login-api error: ' + error);   
}
}

const closeModal = () => {
$('#AcceptAgeModal').hide();
}

return (
<>
<div className='modals' id='AcceptAgeModal'>
<div className='modals-content-sm round10'>
<div className='modals-body condition round10'>
<div className='condition-modal'>
<input type='hidden' id='clientUserModal' />
<input type='hidden' id='clientPassModal' />
<h3>Non-Gambling Territories.</h3>
<hr />
<p>Connecting to our site from non gambling countries, it will be User's responsibility to ensure that their use of the service is lawful.</p>
<p>Underage gambling is prohibited.</p>
<hr />
<p>Please confirm if you are 18 years old and above as of today.</p>

<div className='centerBtns'>
<button id='confloginBtn' onClick={() => confirmLogin()}>confirm</button>
<button onClick={() => closeModal()}>exit</button>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default AcceptAge;
