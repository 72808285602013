import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import AddUpi from '../modals/AddUpi';
import AddBank from '../modals/AddBank';
import ViewAc from './ViewAc';
import { GlobalContext } from '../context/AppContext';

const onlTranApi = '/server/online_transation';

const Withdraw = () => {

const { upiData, bankData, customer, exposure, tranData, GetTranData } = GlobalContext();
const [currDepAmt, setCurrDepAmt] = useState(0);
const [ withUpi, setWithUpi] = useState([]);
const [viewWith, setViewWith] = useState(null);
const [withId, setWithId] = useState(null);
const [pendingWith, setPendingWith] = useState([]);
const [upiMatch, setUpiMatch] = useState([]);
const [bankMatch, setBankMatch] = useState([]);
const [viewAcCurr, setViewAcCurr] = useState(null);

const autoChanAmtDep = (val) => {
if(val <= 0){
setCurrDepAmt(0);
}
else{
setCurrDepAmt(Math.round(val));
}
}

const currDepoMin = () => {
var depoAmt = currDepAmt;
if(depoAmt <= 0){
setCurrDepAmt(0);
}
else{
setCurrDepAmt(parseFloat(depoAmt) - 1);
}
}

const currDepoMax = () => {
var depoAmt = currDepAmt;
setCurrDepAmt(parseFloat(depoAmt) + 1);
}

const setPayStake = (amt) => {
var currAmtState = currDepAmt;
setCurrDepAmt(parseFloat(currAmtState) + parseFloat(amt));
}

const userAddUpi = () => {
$('#userUpiModal').show();
}

const userAddBank = () => {
$('#userBankModal').show();
}

const submitWithReq = async() => {
const findData = withUpi.length > 0 && withUpi.filter((e) => parseInt(e.id) === parseInt(withId));
if(findData.length > 0){
var upiAcName = findData[0].upiAcName !== undefined ? findData[0].upiAcName.toLowerCase() : null;
var upiAcId = findData[0].upiAcId !== undefined ? findData[0].upiAcId.toLowerCase() : null;
var bankName = findData[0].bankName !== undefined ? findData[0].bankName.toLowerCase() : null;
var holderName = findData[0].holderName !== undefined ? findData[0].holderName.toLowerCase() : null;
var bankAcNo = findData[0].bankAcNo !== undefined ? findData[0].bankAcNo : null;
var bankIfsc = findData[0].bankIfsc !== undefined ? findData[0].bankIfsc.toLowerCase() : null;
var receiver = customer.length > 0 ? customer[0].creator : null;
var sender = customer.length > 0 ? customer[0].customer : null;
var sender_role = customer.length > 0 ? customer[0].user_role : null;
var depositAmt = currDepAmt;
var paymentStatus = 'pending';
var paymentType = viewWith;
var paymentMethod = 'withdraw';
var expoUs = exposure.length > 0 ? (exposure[0].exposure !== null ? exposure[0].exposure : 0) : 0;
var userBalC = customer.length > 0 ? (parseFloat(customer[0].total_balance) - parseFloat(expoUs)) : 0;

if(depositAmt <= 0){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('withdraw amount is 0');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(parseFloat(depositAmt) > parseFloat(userBalC) || userBalC === 0){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('you have no sufficiant balance');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post(window.location.protocol +'//ag.'+window.location.host+'/server2/withdraw_online', {
upiAcName : upiAcName,
upiAcId : upiAcId,
bankName : bankName,
holderName : holderName,
bankAcNo : bankAcNo,
bankIfsc : bankIfsc,
receiver : receiver,
sender : sender,
sender_role : sender_role,
depositAmt : depositAmt,
paymentStatus : paymentStatus,
paymentType : paymentType,
paymentMethod : paymentMethod
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('withdraw request submited successfully');
setTimeout(() => {
$('#msgAlert').hide();
setCurrDepAmt(0);
setWithUpi([]);
GetTranData(onlTranApi);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);  
}
} catch (error) {
console.log('frontend withdraw_reqest error : ' + error);
}
}
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('withdraw banking required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
}

useEffect(() => {
if(upiData.length > 0 && customer.length > 0 && viewWith === 'upi'){
setWithUpi(upiData.filter((e) => e.customer === customer[0].customer));
}
else if(bankData.length > 0 && customer.length > 0 && viewWith === 'bank'){
setWithUpi(bankData.filter((e) => e.customer === customer[0].customer));
}
else{
setWithUpi([]); 
}

if(upiData.length > 0 && customer.length > 0){
setUpiMatch(upiData.filter((e) => e.customer === customer[0].customer));
}
if(bankData.length > 0 && customer.length > 0){
setBankMatch(bankData.filter((e) => e.customer === customer[0].customer));
}

},[upiData, bankData, customer, viewWith]);

useEffect(() => {
if(tranData.length > 0 && customer.length > 0){
setPendingWith(tranData.filter((e) => e.sender === customer[0].customer && e.paymentStatus === 'pending' && e.paymentMethod === 'withdraw'));
}
},[tranData, customer]);

useEffect(() => {
if(currDepAmt > 0){
$('#withdraw_typeSel').removeAttr('disabled');
if(withUpi.length > 0){
$('#withdraw_bankSel').removeAttr('disabled');
$('#withdraw_SubBtn').removeClass('disabled').removeAttr('disabled');
}
else{
$('#withdraw_bankSel').attr('disabled', 'disabled');  
$('#withdraw_SubBtn').addClass('disabled').attr('disabled', 'disabled');
}
}
else{
$('#withdraw_typeSel').attr('disabled', 'disabled');
$('#withdraw_bankSel').attr('disabled', 'disabled');
$('#withdraw_SubBtn').addClass('disabled').attr('disabled', 'disabled');
}
},[currDepAmt, withUpi]);

useEffect(() => {
GetTranData(onlTranApi);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

return (
<>
{
pendingWith.length > 0 ?
<div className='paymentDataDiv'>
<div className='payHead'>
<span>withdraw request pending</span>
</div>
<div className='pendingPayDiv'>
<div className='pendingContent'>
<img src='/images/withdraw.png' alt='pending' />
<span>waiting for withdraw confirmaition</span>
<span>after few minutes your withdraw amount will be reflected in your upi / bank account</span>
</div>
</div>
</div>
:
<div className='paymentDataWr'>
<div className='paymentDataDiv'>
<div className='payHead'>
<span>wallet - withdraw</span>
</div>

<div className='payBody'>
<div className='payRow'>
<div className='col'>
<div className="payInputRanDiv">
<span className="leftMin" onClick={currDepoMin}>-</span>
<input type="number" name="depoAmt" placeholder="enter amount" value={currDepAmt} onChange={(e) => autoChanAmtDep(e.target.value)} />
<span className="plusMax" onClick={currDepoMax}>+</span></div>
</div>
<div className='col'>
<div className="depositPayAutBtn">
<button onClick={() => setPayStake(100)}>100+</button>
<button onClick={() => setPayStake(500)}>500+</button>
<button onClick={() => setPayStake(1000)}>1000+</button>
<button onClick={() => setPayStake(5000)}>5000+</button>
<button onClick={() => setPayStake(10000)}>10000+</button>
<button onClick={() => setPayStake(100000)}>100000+</button>
</div>
</div>
</div>
</div>
</div>

<div className='paymentDataDiv'>
<div className='payHead'>
<span>add account options</span>
</div>

<div className='payBody'>
<div className="depositPayBtnDiv">
{
upiMatch.length <= 0 &&
<button className="payOptBtn active" onClick={userAddUpi}>add upi</button>
}
{
bankMatch.length <= 0 &&
<button className="payOptBtn" onClick={userAddBank}>add bank</button>
}
<button className="payOptBtn" onClick={() => setViewAcCurr('view')}>view ac</button>
</div>
</div>
</div>

{
viewAcCurr !== 'view' ?
<div className='paymentDataDiv'>
<div className='payHead'>
<span>withdraw options</span>
</div>

<div className='payBody'>
<div className='depositPayBtnDiv gridMobDepPay'>

<div className='withReqForm'>
<div className='reqForWitData'>
<div className='reqFormWith'>
<label>withdraw amount *</label>
<input type='number' value={currDepAmt} placeholder='Enter Amount...' onChange={(e) => autoChanAmtDep(e.target.value)} />
</div>

<div className='reqFormWith'>
<label>withdraw type *</label>
<select id='withdraw_typeSel' onChange={(e) => setViewWith(e.target.value)} disabled>
<option value='null'>---select withdraw type---</option>
<option value='upi'>upi</option>
<option value='bank'>bank</option>
</select>
</div>

<div className='reqFormWith'>
<label>withdraw banking *</label>
<select id='withdraw_bankSel' onChange={(e) => setWithId(e.target.value)} disabled>
<option value='null'>---select withdraw banking---</option>
{
withUpi.length > 0 && withUpi.map((data, index) => {
return (
<option key={index} value={data.id}>{data.upiAcId !== undefined ? (data.upiType +' - '+ data.upiAcId) : (data.bankName +' -'+ data.bankAcNo)}</option>
)
})
}
</select>
</div>

<div className='reqFormWith reqFormWithbtn'>
<button className='disabled' id='withdraw_SubBtn' onClick={submitWithReq}>submit</button>
</div>
</div>
</div>

</div>
</div>

</div>
:
<ViewAc setViewAcCurr={setViewAcCurr} upiMatch={upiMatch} bankMatch={bankMatch} />
}

</div>
}
<AddUpi />
<AddBank />
</>
)
}

export default Withdraw;
