import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SideMenu from '../extra/SideMenu';
import { GlobalContext } from '../context/AppContext';

const Event = () => {

var location = useLocation();
const Navigate = useNavigate();
const { GetProfitLoss, profitLossUser } = GlobalContext();
const [currRecords, setCurrRecords] = useState([]);
const [totalMain, setTotalMain] = useState(0);
const [selectSport, setSelectSport] = useState(location.state.sport !== undefined ? location.state.sport : 'cricket');
const [startDate, setStartDate] = useState(new Date(location.state.startDate !== undefined ? location.state.startDate : new Date()));
const [endDate, setEndDate] = useState(new Date(location.state.endDate !== undefined ? location.state.endDate : new Date().setDate(new Date().getDate() + 1)));
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = currRecords.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(currRecords.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const marketUsrPls = (sport,event,team,sec,eventid) => {
Navigate('/profit-loss/event/bet_history', {state:{sport: sport, event: event, team: team, sec: sec, eventid: eventid, startDate: startDate, endDate: endDate}});
}

useEffect(() => {
var select_sport = selectSport;
var start_date = new Date(startDate).toDateString();
var end_date = new Date(endDate).toDateString();
GetProfitLoss(select_sport,start_date,end_date);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[selectSport, startDate, endDate]);

useEffect(() => {
if(profitLossUser.length > 0){
setCurrRecords(
profitLossUser.filter((e) => e.settlement === 'settle' && e.sport === (location.state !== null ? location.state.sport : null) && e.eventName === (location.state !== null ? location.state.event : null)).reduce((a, b) => {
const { sport, eventName, team, winner, settle_date, sec, win_status, profit, loss, eventid } = b;

var item = '';
if(sec === 'fancy' || sec === 'ball' || sec === 'over' || sec === 'meter' || sec === 'khado' || sec === 'oddeven' || sec === 'fancy1' || sec === 'line'){
item = a.find((h) => h.team === team && h.eventid === eventid);
}
else{
item = a.find((h) => h.sec === sec && h.eventid === eventid);   
}

let newPrf = 0;
let newLos = 0;
let finalLos = 0;

if(win_status === 'win'){
newPrf = newPrf + profit;
}
else{
newLos = newLos + loss;
}

finalLos = parseFloat(newPrf) - parseFloat(newLos);
item ? item.finalLos += finalLos : a.push({sport,eventName,team,winner,settle_date,sec,finalLos,eventid});
return a;
},[])
);
}else{
setCurrRecords([]);  
}
},[profitLossUser, location.state]);

useEffect(() => {
if(records.length > 0){
const totalAmt = records.reduce((a, b) => {
a = a + b.finalLos;
return a;
},0);

setTotalMain(totalAmt);
}
else{
setTotalMain(0);
}
},[records]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='text-page'>
<div className='text-page-wrap'>
<div className='multimarketdIV'><img src='/images/pls.png' alt='highImg' /> <span>profi loss</span></div>

<div className='simple-div'>

<div className='searchItemsDiv'>
<div className='four-column-row'>
<div className='four-column'>
<div className='input-div-md'>
<label>select sport</label>
<select id='select_sport' onChange={(e) => setSelectSport(e.target.value)} disabled>
<option value={location.state.sport !== undefined ? location.state.sport : 'cricket'}>{location.state.sport !== undefined ? location.state.sport : 'cricket'}</option>
<option value='null'>--select one sport--</option>
<option value='cricket'>cricket</option>
<option value='soccer'>soccer</option>
<option value='tennis'>tennis</option>
<option value='t20'>teenpatti t20</option>
<option value='t20_1day'>teenpatti oneday</option>
<option value='teen_test'>teenpatti test</option>
<option value='dt20'>dragon-tiger 20 20</option>
<option value='dt202'>dragon-tiger 20 20 2</option>
<option value='dt_1day'>dragon-tiger oneday</option>
<option value='lucky7a'>lucky7 a</option>
<option value='lucky7b'>lucky7 b</option>
<option value='andar_bahar'>andar bahar</option>
<option value='head_tail'>head tail</option>
<option value='lucky_09'>lucky 0 to 9</option>
<option value='roll_dice'>roll dice</option>
</select>
</div>
</div>
<div className='four-column'>
<div className='input-div-md'>
<label>start date</label>
<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} disabled />
</div>
</div>
<div className='four-column'>
<div className='input-div-md'>
<label>end date</label>
<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} disabled />
</div>
</div>
</div>
</div>

<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {currRecords.length} records...</span>
</div>

<div className='tabel-responsiveDiv'>
<table className='tabel-md-res'>
<thead>
<tr className='plsThTr'>
<th>sport name</th>
<th>event name</th>
<th>market name</th>
<th>result</th>
<th>profit/loss</th>
<th>commision</th>
<th>settle time</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
return(
<tr key={index} className='plsTr'>
<td>{data.sport}</td>
<td>{data.eventName}</td>
<td>
<span className='links' onClick={() => marketUsrPls(data.sport,data.eventName,data.team,data.sec,data.eventid)}>
{
data.sec === 'fancy' || data.sec === 'ball' || data.sec === 'over' || data.sec === 'meter' || data.sec === 'khado' || data.sec === 'oddeven' || data.sec === 'fancy1' || data.sec === 'line' ? data.team : data.sec
}
</span>
</td>
<td>{data.winner}</td>
<td>{data.finalLos > 0 ? <span className='successTxt'>{parseFloat(data.finalLos.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.finalLos.toFixed(2)).toLocaleString()}</span>}</td>
<td>0</td>
<td>{data.settle_date}</td>
</tr>
)
})
:
<tr className='plsTr'>
<td colSpan='7' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
<tr className='plsTr'>
<td><b>Total</b></td>
<td>--</td>
<td>--</td>
<td>--</td>
<td>{totalMain > 0 ? <span className='successTxt'>{parseFloat(totalMain.toFixed(2)).toLocaleString()}</span> : totalMain < 0 ? <span className='dangerTxt'>{parseFloat(totalMain.toFixed(2)).toLocaleString()}</span> : 0}</td>
<td>0</td>
<td>--</td>
</tr>
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>

</div>

</div>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default Event;
