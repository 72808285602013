import React, { useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext'

const DefaultPass = () => {

const { customer } = GlobalContext();

const autoChangePass = async() => {
var oldPass = $('#oldPassAuto').val();
var newPass = $('#newPassAuto').val();
var confPass = $('#confPassAuto').val();
var currPass = customer.length > 0 ? customer[0].password : null;

if(oldPass === '' || newPass === '' || confPass === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('all fields required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(oldPass !== currPass){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('old password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(newPass !== confPass){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('confirm password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(confPass.length <= 5){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('min 6 digit password required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server/firstPassChange', {
confPass: confPass
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('password change successfully');
setTimeout(function(){
$('#msgAlert').hide();
window.location.reload();
},1000);
return false;  
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('frontend first_change_password_update error : ' + error);
}
}
}

useEffect(() => {
if(customer.length > 0){
if(customer[0].changepass === null && customer[0].coming !== 'online'){
$('#changePass_modal').show();
}
}
},[customer]);

return (
<>
<div className='changePass_modal' id='changePass_modal'>
<div className='changePass_cont'>
<div className='changePass_header'><span><i className='fa fa-key'></i> change password</span></div>
<div className='changePass_body'>

<div className='changePassForm'>
<div className='changePassInput'>
<label htmlFor='oldPassAuto'>old password *</label>
<input type='password' name='oldPassAuto' id='oldPassAuto' placeholder='old paswsword...' />
</div>

<div className='changePassInput'>
<label htmlFor='newPassAuto'>new password *</label>
<input type='text' name='newPassAuto' id='newPassAuto' placeholder='new paswsword...' />
</div>

<div className='changePassInput'>
<label htmlFor='confPassAuto'>confirm password *</label>
<input type='password' name='confPassAuto' id='confPassAuto' placeholder='confirm paswsword...' />
</div>

<div className='changePassInput'>
<button onClick={autoChangePass}>confirm changes</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default DefaultPass;
