import React from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import SideMenu from '../extra/SideMenu';
import { GlobalContext } from '../context/AppContext';

const MobileAc = () => {

const { customer } = GlobalContext();
const Navigate = useNavigate();

const myStake = () => {
Navigate('/stake-setting');
}

const myBetHistory = () => {
Navigate('/bet-history');    
}

const myPls = () => {
Navigate('/profit-loss');
}

const myActivity = () => {
Navigate('/activity-log');  
}

const myChangePass = () => {
Navigate('/change-password');    
}

const myStatement = () => {
Navigate('/statement'); 
}

const myBanking = () => {
Navigate('/online_banking');  
}

const myPrfs = () => {
Navigate('/my_profile');   
}

const mylogOut = async() => {
try {
const sendData = await axios.get('/server/logout');
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('logout successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.replace('/');
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('frontend logout-api error: ' + error);
}
}

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='game-body'>
<div className='game-contents'>

<div className='mobileAccount'>
<div className="highlightdIV"><img src="/images/userMobile.png" alt="highImg" /> <span>Username : {customer.length > 0 ? customer[0].customer : 'no user'}</span></div>

<ul className='accountMobUl'>
<li onClick={() => myPrfs()}><span><i className='fa fa-angle-double-right'></i> my profile</span></li>
<li onClick={() => myStake()}><span><i className='fa fa-angle-double-right'></i> stake setting</span></li>
<li onClick={() => myBetHistory()}><span><i className='fa fa-angle-double-right'></i> bet history</span></li>
<li onClick={() => myPls()}><span><i className='fa fa-angle-double-right'></i> profit / loss</span></li>
<li onClick={() => myStatement()}><span><i className='fa fa-angle-double-right'></i> statement</span></li>
<li onClick={() => myBanking()}><span><i className='fa fa-angle-double-right'></i> banking</span></li>
<li onClick={() => myActivity()}><span><i className='fa fa-angle-double-right'></i> activity log</span></li>
<li onClick={() => myChangePass()}><span><i className='fa fa-angle-double-right'></i> change password</span></li>
<div className='logoutDiv'>
<button onClick={() => mylogOut()}><i className='fa fa-sign-out'></i> logout</button>
</div>
</ul>

</div>

</div>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default MobileAc;
