import $ from 'jquery';
import axios from 'axios';
import { getAllBetsMarket } from '../../../allBets/Betsmarket';

function oddsClick_t20(sid){
let betRate = $('#casRate_t20_'+sid).text();
var teamname = '';
if(sid === '1' || sid === '2'){
teamname = 'player a';   
}
else{
teamname = 'player b';  
}
$('#teamCas_t20').text(teamname);
$('#bet_rate_t20').val(betRate);
$('#bet_amount_t20').val(0);
$('#teamSid_t20').text(sid);
$('#placeBetCasino_t20').show();
$('#betsPlace_t20').removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_t20').removeClass('active').attr('disabled', 'disabled');
$('#plsCasBef_t20_1').css({color: '#000000'}).text('('+0+')');
$('#plsCasBef_t20_3').css({color: '#000000'}).text('('+0+')');
}

function cancelBet_t20(){
$('#placeBetCasino_t20').hide();
$('#teamCas_t20').text('');
$('#bet_rate_t20').val();
$('#teamSid_t20').text('');
$('#plsCasBef_t20_1').hide();
$('#plsCasBef_t20_3').hide();
}

function minusAmt_t20(){
let betAmt = $('#bet_amount_t20').val();
$('#bet_amount_t20').val((parseFloat(betAmt) / 2).toFixed());
beforePls_t20();
}

function plusAmt_t20(){
let betAmt = $('#bet_amount_t20').val();
$('#bet_amount_t20').val((parseFloat(betAmt) + parseFloat(betAmt)).toFixed());
beforePls_t20();
}

function changeAmt_t20(val){
if(parseFloat(val !== '' ? val : 0) <= 0){
$('#betsPlace_t20').removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_t20').removeClass('active').attr('disabled', 'disabled');
$('#bet_amount_t20').val(0);
}else{
$('#betsPlace_t20').addClass('active').removeAttr('disabled'); 
$('#betsPlace2_t20').addClass('active').removeAttr('disabled');    
$('#bet_amount_t20_').val(Math.round(val));
}
beforePls_t20();
}

function stakeBet_t20(amt){
$('#bet_amount_t20').val(amt);
$('#betsPlace_t20').addClass('active').removeAttr('disabled');
$('#betsPlace2_t20').addClass('active').removeAttr('disabled');
beforePls_t20();
}

function beforePls_t20(){
let sid = $('#teamSid_t20').text();
let rate = $('#bet_rate_t20').val();
let betAmt = $('#bet_amount_t20').val(); 
let a = 0;
let b = 0;

a = parseFloat((rate * betAmt - betAmt).toFixed(2)).toLocaleString();
b = betAmt;

if(sid === '1' || sid === '2'){
$('#plsCasBef_t20_1').css({color: '#44B10A'}).show().text('('+a+')');
$('#plsCasBef_t20_3').css({color: '#bd0000'}).show().text('('+b+')');
}
else{
$('#plsCasBef_t20_3').css({color: '#44B10A'}).show().text('('+a+')');
$('#plsCasBef_t20_1').css({color: '#bd0000'}).show().text('('+b+')');
}
}

function betSend_t20(){
$('#betMsgWrapCas_t20').css({display: 'grid'});
$('.odds_cas').attr('disabled', 'disabled');
setTimeout(function(){
finalPlaceBet_t20();
},2000);
}

async function finalPlaceBet_t20(){
var selBetType = $('#selBetType_t20').text();
var sec = $('#sec_t20').text();
var sport = $('#sport_t20').text();
var eventname = 'casino';
var teamCas_t20 = $('#teamCas_t20').text();
var bet_rate_t20 = $('#bet_rate_t20').val();
var bet_amount_t20 = $('#bet_amount_t20').val();
var eventid = $('#eventid_t20').text();
var userCurr = $('#userCurr_t20').text();
var betdelay = 2;
let sid = $('#teamSid_t20').text();

let minBet = 0;
if(userCurr === 'inr'){
minBet = 100;
}
else{
minBet = 1;  
}

if(eventid === '0'){
$('#bet_msgCas_t20').show().html('roundid changed');
setTimeout(function(){
$('#betMsgWrapCas_t20').css({display: 'none'});
$('#bet_msgCas_t20').hide().html('');
$('.odds_cas').removeAttr('disabled');
},1000);
return false;
}
if(bet_amount_t20 === '' || bet_amount_t20 < minBet){
$('#bet_msgCas_t20').show().html('minimum betamount ' + minBet);
setTimeout(function(){
$('#betMsgWrapCas_t20').css({display: 'none'});
$('#bet_msgCas_t20').hide().html('');
$('.odds_cas').removeAttr('disabled');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server/casino_saveBet', {
selBetType: selBetType,
sec: sec,
sport: sport,
eventname: eventname,
teamCas: teamCas_t20,
bet_rate: bet_rate_t20,
bet_amount: bet_amount_t20,
eventid: eventid,
betdelay: betdelay,
sid: sid
});
const resData = await sendData.data;
if(resData === 'success'){
setExposure();
getAllBetsMarket(eventid);
pls_t20(eventid,sport);
$('#bet_msgCas_t20').show().html('bet placed');
setTimeout(function(){
$('#betMsgWrapCas_t20').css({display: 'none'});
$('#bet_msgCas_t20').hide().html('');
$('.odds_cas').removeAttr('disabled');
cancelBet_t20();
},1000);
return false;
}
else{
$('#bet_msgCas_t20').show().html(resData);
setTimeout(function(){
$('#betMsgWrapCas_t20').css({display: 'none'});
$('#bet_msgCas_t20').hide().html('');
$('.odds_cas').removeAttr('disabled');
cancelBet_t20();
},1000);
}
} catch (error) {
console.log('frontend t20_send_savebet data : ' + error);
}
}
}

async function setExposure(){
let currBal = 0;
let currExpo = 0;
const getBal = await axios.get('/server/active-customer');
const balRes = await getBal.data;
if(balRes.length > 0){
currBal = balRes[0].total_balance;
}

const getExpo = await axios.get('/server/user-exposure');
const expoRes = await getExpo.data;
if(expoRes.length > 0){
if(expoRes[0].exposure !== null){
currExpo = expoRes[0].exposure;
}
}

$('#mainBal').html(parseFloat((currBal - currExpo).toFixed(2)).toLocaleString());
$('#mainExpo').html(parseFloat((currExpo).toFixed(2)).toLocaleString());
//console.log('curr balance : '+currBal +' exposure : '+ currExpo);

}

async function pls_t20(eventid,sport){
try {
const getData = await axios.get('/server/all-bets');
const resData = await getData.data;
const response = resData.filter((e) => e.sport === sport && e.eventid === eventid);
if(response.length > 0){
var t1Prf = 0;
var t2Prf = 0;
var runner1 = 'player a';
var runner2 = 'player b';

response.forEach((exx) => {
if(exx.team.trim() === runner1 && exx.bettype === 'back'){
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
}

if(exx.team.trim() === runner2 && exx.bettype === 'back'){
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
}
});

if(t1Prf > 0){
$('#plsCas_t20_1').html(parseFloat(t1Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#plsCas_t20_1').html(parseFloat(t1Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}

if(t2Prf > 0){
$('#plsCas_t20_3').html(parseFloat(t2Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#plsCas_t20_3').html(parseFloat(t2Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}

}
} catch (error) {
console.log('frontend t20_pls bets api : ' + error);
}
}

export {
oddsClick_t20,
cancelBet_t20,
minusAmt_t20,
plusAmt_t20,
changeAmt_t20,
stakeBet_t20,
betSend_t20,
pls_t20
};