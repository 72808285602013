import React from 'react';
import { useLocation } from 'react-router-dom';
import Social from './Social';
import Notice from'./Notice';
import TopHeader from './TopHeader';
import Header from './Header';
import MainMenu from './MainMenu';

const HeadFix = () => {
const { pathname } = useLocation();
return (
<>
{
pathname !== '/ludoking' &&
<div className='fixHeader'>
<Social />
<Notice />
<TopHeader />
<Header />
<MainMenu />
</div>
}
</>
)
}

export default HeadFix;
