import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import SideMenu from '../../extra/SideMenu';
import { GlobalContext } from '../../context/AppContext';
import MarketBets from '../../allBets/MarketBets';
import { check_dt1day, start_dt1day } from './js/refresh';
import { startResult_dt1day, runresult_dt1day } from './js/result';
import ResultDt from '../modals/ResultDt';
import { startSettle_dt1day, runSettle_dt1day } from './js/settle';
import DtRules from '../modals/DtRules';
import { 
oddsClick_dt1day,
cancelBet_dt1day,
minusAmt_dt1day,
plusAmt_dt1day,
changeAmt_dt1day,
betSend_dt1day,
stakeBet_dt1day,
allDt1daySid,
pls_dt1day
} from './js/betting';

const Dt1Day = () => {

const [load_dt1day, setLoad_dt1Day] = useState(true);
const [currdt1day, setCurrDt1Day] = useState([]);
const { customer } = GlobalContext();

const opendt1dayRules = () => {
$('#dtModal').show();
}

useEffect(() => {
async function fetchDt1day() {
try {
const getData = await axios.get('/server/dt1day_Data');
const resData = await getData.data;
if(resData.data !== undefined){

resData.data.t2.forEach(element => {
var data = {
sid : element.sid,
nat : element.nat
}

const findInd = allDt1daySid.findIndex((o) => o.sid === element.sid && o.nat === element.nat);
if(findInd === -1){
allDt1daySid.push(data);
}
});

setLoad_dt1Day(false);
setCurrDt1Day(resData);
}
else{
setLoad_dt1Day(true);
setCurrDt1Day([]);
}
} catch (error) {
console.log('frontend casino_dt1day_data error : ' + error);
}
}

fetchDt1day();
},[]);

useEffect(() => {
check_dt1day();
runresult_dt1day();
startSettle_dt1day();
pls_dt1day(currdt1day.data !== undefined ? currdt1day.data.t1[0].mid : 0,'dt_1day');
return () => {
clearInterval(start_dt1day);
clearInterval(startResult_dt1day);
clearInterval(runSettle_dt1day);
}
},[currdt1day]);

if(load_dt1day){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='game-body'>
<div className='game-contents'>

{
currdt1day.data !== undefined &&
<div className='casinoGame_wrap'>
<div className='headerCasGame'>
<div className='col'>
<span className='casGameTitle'>DT 1Day</span>
<span className='casGamerule' onClick={opendt1dayRules}>rules</span>
</div>
<div className='col'>
<span className='casRoundId'>mid : <span id='roundId_dt1day'>{currdt1day.data.t1[0].mid}</span></span>
</div>
</div>

<div className='videoWrapCas'>
<div className='liveCards'>
<div className='col'>
<span className='cardTeam'>cards</span>
<div className='teamLiveCards'>
<img id='card1_dt1day' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(currdt1day.data.t1[0].c1.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card2_dt1day' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(currdt1day.data.t1[0].c2.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div>    
</div>   
<div className='countDownWrap'>
<div className='countDown'></div> 
<div className='countTxt'><span id='autoTime_dt1day'>{currdt1day.data.t1[0].autotime}</span></div>   
</div> 
<div className='frameWrap'>
<iframe src='https://alpha-n.qnsports.live/route/ash.php?id=3057' title='casino video'></iframe>
</div>
</div>

<div className='casDataWrap'>
<table className='casTable'>
<thead>
<tr>
<th></th>
<th className='backTh2'><span>back</span></th>
<th className='layTh'><span>lay</span></th>
</tr>
</thead>
{
currdt1day.data.t2.map((data, index) => {
return(
<tbody key={index}>
<tr>
<td>
<div className='teamTdCas'>
<span>{data.nat}</span>
<div className='plsDiv'>
<span className='plsCas dt1dayPls' id={'plsCas_dt1day_'+data.sid}>0</span>
<span className='plsCas plsCasBef' id={'plsCasBef_dt1day_'+data.sid}></span>
</div>
</div>
</td>
<td className='countOdds backCasBox'>
<div className='locCasino' id={'locCasino_dt1day_'+data.sid}><i className='fa fa-lock'></i></div>
<button className='odds_cas' id={'odds_casBack_dt1day_'+data.sid} onClick={() => oddsClick_dt1day(data.sid,data.nat,'back')}>
<span className='casRate' id={'casRateBack_dt1day_'+data.sid}>{data.b1}</span>
<span className='casSize' id={'casSizeBack_dt1day_'+data.sid}>{data.bs1}</span>
</button>
</td>
<td className='countOdds layCasBox'>
<button className='odds_cas' id={'odds_casLay_dt1day_'+data.sid} onClick={() => oddsClick_dt1day(data.sid,data.nat,'lay')}>
<span className='casRate' id={'casRateLay_dt1day_'+data.sid}>{data.l1}</span>
<span className='casSize' id={'casSizeLay_dt1day_'+data.sid}>{data.ls1}</span>
</button>
</td>
</tr>

<tr style={{display:'none'}} className='placeBetCasino_dt1day' id={'placeBetCasino_dt1day_'+data.sid}>
<td style={{padding:'0'}} colSpan='3'>
<div className='wrapbet-row'>
<div className='bet-msgWrap' id={'betMsgWrapCas_dt1day_'+data.sid}>
<div className='loaderDiv'>
<span className='dot1'></span>
<span className='dot2'></span>
<span className='loadTx'>Loading...</span>
</div>
<span id={'bet_msgCas_dt1day_'+data.sid}></span>
</div>
<div className='bet-input-row'>
<span style={{display:'none'}} id={'selBetType_dt1day_'+data.sid}></span>
<span style={{display:'none'}} id={'sec_dt1day_'+data.sid}>match_odds</span>
<span style={{display:'none'}} id={'sport_dt1day_'+data.sid}>dt_1day</span>
<span style={{display:'none'}} id={'teamCas_dt1day_'+data.sid}></span>
<span style={{display:'none'}} id={'teamSid_dt1day_'+data.sid}></span>
<span style={{display:'none'}} id={'userCurr_dt1day_'+data.sid}>{customer.length > 0 ? customer[0].currency : 'inr'}</span>
<span style={{display:'none'}} id={'eventid_dt1day_'+data.sid}>{currdt1day.data.t1[0].mid}</span>

<div className='col'>
<div className='cancel'>
<button onClick={() => cancelBet_dt1day(data.sid)}>cancel</button>
</div>

<div>
<span>-</span>
<input type='number' id={'bet_rate_dt1day_'+data.sid} placeholder='0' disabled />
<span>+</span>
</div>

<div>
<span onClick={() => minusAmt_dt1day(data.sid)}>-</span>
<input type='number' id={'bet_amount_dt1day_'+data.sid} placeholder='0' onChange={(e) => changeAmt_dt1day(data.sid,e.target.value)} />
<span onClick={() => plusAmt_dt1day(data.sid)}>+</span>
</div>

<div className='place'>
<button onClick={() => betSend_dt1day(data.sid)} className='' id={'betsPlace_dt1day_'+data.sid}>place bet</button>
</div>

</div>
</div>

<div className='colbtnBet' id={'betColBet_dt1day_'+data.sid}>
<button onClick={() => stakeBet_dt1day(data.sid,customer.length > 0 ? customer[0].stake1 : 100)}>{customer.length > 0 ? customer[0].stake1 : 100}</button>
<button onClick={() => stakeBet_dt1day(data.sid,customer.length > 0 ? customer[0].stake2 : 200)}>{customer.length > 0 ? customer[0].stake2 : 200}</button>
<button onClick={() => stakeBet_dt1day(data.sid,customer.length > 0 ? customer[0].stake3 : 500)}>{customer.length > 0 ? customer[0].stake3 : 500}</button>
<button onClick={() => stakeBet_dt1day(data.sid,customer.length > 0 ? customer[0].stake4 : 1000)}>{customer.length > 0 ? customer[0].stake4 : 1000}</button>
<button onClick={() => stakeBet_dt1day(data.sid,customer.length > 0 ? customer[0].stake5 : 2000)}>{customer.length > 0 ? customer[0].stake5 : 2000}</button>
<button onClick={() => stakeBet_dt1day(data.sid,customer.length > 0 ? customer[0].stake6 : 5000)}>{customer.length > 0 ? customer[0].stake6 : 5000}</button>
<button onClick={() => stakeBet_dt1day(data.sid,customer.length > 0 ? customer[0].stake7 : 10000)}>{customer.length > 0 ? customer[0].stake7 : 10000}</button>
<button onClick={() => stakeBet_dt1day(data.sid,customer.length > 0 ? customer[0].stake8 : 25000)}>{customer.length > 0 ? customer[0].stake8 : 25000}</button>
</div>

<div className='mobilePlace'>
<div className='cancel'>
<button onClick={() => cancelBet_dt1day(data.sid)}>cancel</button>
</div>

<div className='place'>
<button onClick={() => betSend_dt1day(data.sid)} className='' id={'betsPlace2_dt1day_'+data.sid}>place bet</button>
</div> 
</div>
</div>
</td>
</tr>

</tbody>
)
}) 
}
</table>
</div>

<div className='casinoResWrap'>
<div className='casinoResTitle'><span>last result</span></div>
<div className='allResCas' id='allResCas_dt1day'></div>
</div>

<MarketBets eventid={currdt1day.data !== undefined ? currdt1day.data.t1[0].mid : 0} />
</div>
}

</div>
</div>
</div>
</div>
</div>
</div>

<DtRules />
<ResultDt />
</>
)
}

export default Dt1Day;
