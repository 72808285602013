import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import SideMenu from '../extra/SideMenu';
import Footer from '../extra/Footer';
import { GlobalContext } from '../context/AppContext';
import AcceptAge from '../modals/AcceptAge';
import FrontPay from '../pages/FrontPay';

const CricListApi = '/server/cricket-list';
const MultiApi = '/server/customer-multimarket';

const Cricket = () => {

const Navigate = useNavigate();
const { thGameImg, cicList, GetCricList, customer, multimarket, GetMultiList, spoSett, gameSett, cricCompAll } = GlobalContext();
const [lockSport, setLockSport] = useState([]);
const [pageCricImg, setPageCricImg] = useState([]);

const pinEvent = async(eid,gmid,mid) => {
if (customer.length > 0){
//alert(eid +' -- '+ gmid +' -- '+ mid);
try {
const sendData = await axios.post('/server/multimarkets', {customer:customer[0].customer, eid:eid, gmid:gmid, mid:mid});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('pin event successfully');
GetMultiList(`${MultiApi}`);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else if(resData === 'removed'){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('pin removed successfully');
GetMultiList(`${MultiApi}`);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);   
}
} catch (error) {
console.log('frontend cricket multiadd-api error: ' + error);
}
}
else{
$('#AcceptAgeModal').fadeIn();
}
}

const pinEventData = cicList.filter(i => multimarket.some(j => parseFloat(i.gameid) === parseFloat(j.gmid) && parseFloat(i.marketid) === parseFloat(j.mid)));
const noPinEventData = cicList.filter(i => !multimarket.some(j => parseFloat(i.gameid) === parseFloat(j.gmid) && parseFloat(i.marketid) === parseFloat(j.mid)));

const openMarket = (eventid, marketid) => {
Navigate('/market-4/'+eventid+'/'+marketid);
}

useEffect(() => {
GetCricList(`${CricListApi}`);
/* eslint-disable react-hooks/exhaustive-deps */
}, []);

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent || e.username === f.customer)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.customer)));
}
}
},[customer, spoSett]);

useEffect(() => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'cricket');
//console.log(checkLock);
if(checkLock.length > 0){
$('#sports_lock2').css({display: 'flex'});
}else{
$('#sports_lock2').hide();
}
}
},[lockSport]);

useEffect(() => {
if(cicList.length > 0){
cricCompAll.filter((e) => gameSett.find((o) => parseFloat(e.comp_id) === parseFloat(o.eventid) && o.sport === 'cricket')).forEach((k) => {
$('#GameLock_'+k.event_id).css({display: 'flex'});
});
}
},[gameSett, cricCompAll, cicList]);

useEffect(() => {
if(thGameImg.length > 0){
setPageCricImg(thGameImg.filter((e) => e.sport === 'cricket'));
}
},[thGameImg]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='game-body'>
<div className='singleImage'>
{
pageCricImg.length > 0 ?
<img src={pageCricImg[0].path} alt='singleImg' />
:
<img src='/images/slide1.png' alt='singleImg' />
}
</div>

<FrontPay />

<div className='game-contents'>
<div className='highlightdIV'><img src='/images/cricket.png' alt='highImg' /> <span>cricket highlights</span></div>

<div className='sports-page'>

<div className='sports_lock' id='sports_lock2'>
<div className='sport_lockTxt'>
<img src='/images/lock.png' alt='lock' />
<span>sport is locked</span>
<span>contact your service provider</span>
</div>
</div>

<table className='sports-page-tabel'>
<thead>
<tr>
<th></th>
<th></th>
<th>1</th>
<th>X</th>
<th>2</th>
<th></th>
</tr>
</thead>
{
cicList.length > 0 && cicList.map((data, index) => {
var fTrue = '';
var tvTrue = '';
var inPlay = '';
var ename = data.eventname.split('/');
var splitDate = ename[1].replaceAll('(ist)', '').replaceAll('am', ' am').replaceAll('pm', ' pm');
var eventTime = '';
var timeParse = Date.parse(splitDate);
if(data.f === 'true'){
fTrue = <span className='f-avail'><img src='../images/letter-f.png' alt='sportsIco' /></span>
}
if(data.tv === 'true'){
tvTrue = <span className='tv-avail'><img src='../images/tv.png' alt='sportsIco' /></span>
}
if(data.inplay === 'true'){
inPlay = <span className='inplay-avail'><img src='../images/green-circle.png' alt='sportsIco' /></span>
}
else{
eventTime = <span className='event-time'>{new Date(timeParse).toLocaleString()}</span>;
}


return(
<tbody key={index}>
<tr>
<td className='GameLock' id={'GameLock_'+data.gameid}>
<span>event is locked <i className='fa fa-lock colLock'></i></span>
</td>
</tr>

<tr>
<td>
<span className='blockSpan'>
<span onClick={() => openMarket(data.gameid, data.marketid)} className='event-name'>{ename[0].trim()}</span>
{eventTime}
</span>
</td>
<td><span className='blockSpan2'>{inPlay}{fTrue}{tvTrue}</span></td>
<td className='counts-rates'>
<span className='back-sports-page'>{data.back1}</span>
<span className='lay-sports-page'>{data.lay1}</span>
</td>
<td className='counts-rates'>
<span className='back-sports-page'>{data.back11}</span>
<span className='lay-sports-page'>{data.lay11}</span>
</td>
<td className='counts-rates'>
<span className='back-sports-page'>{data.back12}</span>
<span className='lay-sports-page'>{data.lay12}</span>
</td>
{
pinEventData.length > 0 && pinEventData.filter(h => h.gameid === data.gameid).map((data2, index2) => {
return (
<td key={index2} onClick={() => pinEvent('4', data2.gameid, data2.marketid)}><img className='pinImg' src='../images/pin-green.png' alt='pngIco' /></td>
)
})
}

{
noPinEventData.length > 0 && noPinEventData.filter(h => h.gameid === data.gameid).map((data3, index3) => {
return (
<td key={index3} onClick={() => pinEvent('4', data3.gameid, data3.marketid)}><img className='pinImg' src='../images/pin.png' alt='pngIco' /></td>
)
})
}
</tr>
</tbody>
)
})
}
</table>
</div>

<Footer />
</div>
</div>
</div>

</div>
</div>
</div>

<AcceptAge />
</>
)
}

export default Cricket;
