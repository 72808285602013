import axios from 'axios';

async function reject(){
try {
const getData = await axios.get('/server/reject_casino');
const resData = await getData.data;
if(resData === 'success'){
console.log('success reject casino');
}
else{
console.log(resData);
}
} catch (error) {
console.log('frontend casino_reject error : ' + error);
}
}

export { reject };
