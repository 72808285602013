import React, { useEffect, useState } from 'react';
//import $ from 'jquery';
import SideMenu from '../../extra/SideMenu';
import AcceptAge from '../../modals/AcceptAge'
import { GlobalContext } from '../../context/AppContext';
import MarketBets from '../../allBets/MarketBets';
import { 
setMarketId,
runTimerNow,
startTimer,
startFlip,
headOddsClick,
cancelBet_head,
stakeBet_head,
betSend_head,
resultHeadTail,
settle_head,
headTime1,
headTime2,
playSound
} from './js/headtail';

const HeadTails = () => {

const { customer, spoSett } = GlobalContext();
const [lockSport, setLockSport] = useState([]);
const [lockShow, setLockShow] = useState(false);

useEffect(() => {
setMarketId();
runTimerNow();
resultHeadTail();
return () => {
clearInterval(startTimer);
clearTimeout(startFlip);
clearTimeout(headTime1);
clearTimeout(headTime2);
settle_head();
playSound(false);
}
},[]);

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent || e.username === f.customer)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.customer)));
}
}
},[customer, spoSett]);

useEffect(() => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
//console.log(checkLock);
if(checkLock.length > 0){
setLockShow(true);
}else{
setLockShow(false);
}
}
},[lockSport]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='game-body'>
<div className='game-contents'>

<div className='casino-wrap'>

{
lockShow === true && 
<div className='sports_lock' id='sports_lock2' style={{display: 'flex'}}>
<div className='sport_lockTxt'>
<img src='/images/lock.png' alt='lock' />
<span>sport is locked</span>
<span>contact your service provider</span>
</div>
</div>
}

<div className='casino-content'>
<div className='casino-headRow'>
<div className='col'><img src='/images/coin.png' alt='highImg' /><span>Head Tails</span></div>
<div className='col'><span>Market Id:</span><span id='marketid'>0</span></div>
</div>

<div className='coins-wrap'>
<div className='countdown-div'><span id='countstart'>0</span></div>

<div className='coins'>
<div className='head-div'>
<div className='coin-div'>
<div>
<span>₹</span>
<span>heads</span>
</div>
</div>
</div>
<div className='tail-div'>
<div className='coin-div'>
<div>
<span>₹</span>
<span>tails</span>
</div>
</div>
</div>
</div>
</div>

<div className='result-casino'>
<div className='col'><span>Result -</span></div>
<div className='col'>
<div className='resultcasDiv' id='resultHead'></div>
</div>
</div>

<div className='head_odds'>

<div style={{display:'none'}} className='placeBetCasino' id='placeBetCasino_head'>
<div style={{padding:'0'}} colSpan='3'>
<div className='wrapbet-row'>
<div className='bet-msgWrap' id='betMsgWrapCas_head'>
<div className='loaderDiv'>
<span className='dot1'></span>
<span className='dot2'></span>
<span className='loadTx'>Loading...</span>
</div>
<span id='bet_msgCas_head'></span>
</div>

<div className='betTeamSec'>
<span>selected coin : lucky coin <span id='teamCas_head'></span></span>
</div>

<div className='bet-input-row'>
<span style={{display:'none'}} id='selBetType_head'>back</span>
<span style={{display:'none'}} id='sec_head'>match_odds</span>
<span style={{display:'none'}} id='sport_head'>head_tail</span>
<span style={{display:'none'}} id='teamSid_head'>1</span>
<span style={{display:'none'}} id='userCurr_head'>{customer.length > 0 ? customer[0].currency : 'inr'}</span>
<span style={{display:'none'}} id='eventid_head'>0</span>

<div className='col'>
<div className='cancel'>
<button onClick={() => cancelBet_head()}>cancel</button>
</div>

<div>
<span>-</span>
<input type='number' id='bet_rate_head' placeholder='1.98' disabled />
<span>+</span>
</div>

<div>
<span>-</span>
<input type='number' id='bet_amount_head' placeholder='0' disabled />
<span>+</span>
</div>

<div className='place'>
<button onClick={() => betSend_head()} className='' id='betsPlace_head'>place bet</button>
</div>

</div>
</div>

<div className='colbtnBet' id='betColBet_head'>
<button onClick={() => stakeBet_head(customer.length > 0 && (customer[0].currency === 'inr' ? 100 : 100 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 100 : 100 / 100)}</button>
<button onClick={() => stakeBet_head(customer.length > 0 && (customer[0].currency === 'inr' ? 250 : 250 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 250 : 250 / 100)}</button>
<button onClick={() => stakeBet_head(customer.length > 0 && (customer[0].currency === 'inr' ? 500 : 500 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 500 : 500 / 100)}</button>
<button onClick={() => stakeBet_head(customer.length > 0 && (customer[0].currency === 'inr' ? 1000 : 1000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 1000 : 1000 / 100)}</button>
<button onClick={() => stakeBet_head(customer.length > 0 && (customer[0].currency === 'inr' ? 2000 : 2000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 2000 : 2000 / 100)}</button>
<button onClick={() => stakeBet_head(customer.length > 0 && (customer[0].currency === 'inr' ? 5000 : 5000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 5000 : 5000 / 100)}</button>
<button onClick={() => stakeBet_head(customer.length > 0 && (customer[0].currency === 'inr' ? 10000 : 10000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 10000 : 10000 / 100)}</button>
<button onClick={() => stakeBet_head(customer.length > 0 && (customer[0].currency === 'inr' ? 15000 : 15000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 15000 : 15000 / 100)}</button>
</div>

<div className='mobilePlace'>
<div className='cancel'>
<button onClick={() => cancelBet_head()}>cancel</button>
</div>

<div className='place'>
<button onClick={() => betSend_head()} className='' id='betsPlace2_head'>place bet</button>
</div> 
</div>
</div>
</div>
</div>

<div className='headTails_odds_row'>
<div className='lockhead'><i className='fa fa-lock'></i></div>
<div className='col'>
<span className='headRate'>1.98</span>
<button className='head_oddsBtn' onClick={() => headOddsClick('head')}><span>head</span></button>
</div>
<div className='col'>
<span className='headRate'>1.98</span>
<button className='head_oddsBtn' onClick={() => headOddsClick('tail')}><span>tail</span></button>
</div>
</div>

</div>

<div className='allBetsWrap'><MarketBets eventid={0} /></div>

</div>

</div>
</div>
</div>
</div>
</div>
</div>
</div>

<AcceptAge />
</>
)
}

export default HeadTails;
