import React from 'react';
import $ from 'jquery';

const ResultMod = () => {

const closeDetRes = () => {
$('#casResModal').hide();
}

return (
<>
<div className='casResModal' id='casResModal'>
<div className='casResCont'>
<div className='casResHead'><span>details result</span> <i className='fa fa-close' onClick={closeDetRes}></i></div>
<div className='casResBody'>

<div className='casDetResLoad'>
<span></span>
</div>

<div className='resCasCon'>
<div className='cardsRow'>
<div className='col'>
<span className='cardTeamCas'>player a</span>
<div className='cardsImg'>
<img id='img1' alt='card_img' />
<img id='img2' alt='card_img' />
<img id='img3' alt='card_img' />
</div>
</div>
<div className='col'>
<span className='cardTeamCas'>player b</span>
<div className='cardsImg'>
<img id='img4' alt='card_img' />
<img id='img5' alt='card_img' />
<img id='img6' alt='card_img' />
</div>
</div>
</div>

<div className='casResWin'>
<span className='casRestxt'>winner : <span id='winCas'></span></span>
<span className='casRestxt'>mid : <span id='winCasMid'></span></span>
</div>
</div>

<div className='record_no' id='record_no_teen'>
<img src='/images/poker_rec.png' alt='poker_rec' />   
<span>no records found...</span>
</div>

</div>
</div>
</div>
</>
)
}

export default ResultMod;
