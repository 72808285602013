import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { reject } from '../extra/rejectCasino';
import { GlobalContext } from '../context/AppContext';

const DemoLogin = () => {

const { userIp } = GlobalContext();

const closeDemoLog = () => {
$('#demoLoginModal').hide();
}

const demoLogin = async() => {
const customer = 'demo';
const password = 'demo123';
const user_ip = userIp.length !== 0 ? userIp.ip : null;
const city = userIp.length !== 0 ? userIp.city : null;
const region = userIp.length !== 0 ? userIp.region : null;
const country = userIp.length !== 0 ? userIp.country : null;
const org = userIp.length !== 0 ? userIp.org : null;
const postal = userIp.length !== 0 ? userIp.postal : null;
const timezone = userIp.length !== 0 ? userIp.timezone : null;
$('#demologinBtn').attr('disabled', 'disabled');
try {
const sendData = await axios.post('/server/login', {
customer: customer,
password: password,
user_ip: user_ip,
city: city,
region: region,
country: country,
org: org,
postal: postal,
timezone: timezone
});
const resData = await sendData.data;
if(resData === 'success'){
$("#msgAlert").fadeIn().addClass('alert-success').removeClass('alert-danger').html('login successfull');
setTimeout(function(){
$("#msgAlert").hide();
reject();
window.location.reload();
},1000);
return false;   
}
else{
$("#msgAlert").fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(function(){
$("#msgAlert").hide();
$('#demologinBtn').removeAttr('disabled');
},1000);
}
} catch (error) {
console.log('frontend demo-login-api error: ' + error);   
}
}

const demoLogin2 = async() => {
const customer = 'demo2';
const password = 'demo12345';
const user_ip = userIp.length !== 0 ? userIp.ip : null;
const city = userIp.length !== 0 ? userIp.city : null;
const region = userIp.length !== 0 ? userIp.region : null;
const country = userIp.length !== 0 ? userIp.country : null;
const org = userIp.length !== 0 ? userIp.org : null;
const postal = userIp.length !== 0 ? userIp.postal : null;
const timezone = userIp.length !== 0 ? userIp.timezone : null;
$('#demologinBtn2').attr('disabled', 'disabled');
try {
const sendData = await axios.post('/server/login', {
customer: customer,
password: password,
user_ip: user_ip,
city: city,
region: region,
country: country,
org: org,
postal: postal,
timezone: timezone
});
const resData = await sendData.data;
if(resData === 'success'){
$("#msgAlert").fadeIn().addClass('alert-success').removeClass('alert-danger').html('login successfull');
setTimeout(function(){
$("#msgAlert").fadeOut('slow');
window.location.reload();
},1000);
return false;   
}
else{
$("#msgAlert").fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(function(){
$("#msgAlert").fadeOut('slow');
$('#demologinBtn2').removeAttr('disabled');
},1000);
}
} catch (error) {
console.log('frontend demo2-login-api error: ' + error);   
}
}

return (
<>
<div className='demoLoginModal' id='demoLoginModal'>
<div className='demoLoginModalCon'>
<div className='demoLoginModalHead'>
<span>demo login</span>
<i className='fa fa-close' onClick={closeDemoLog}></i>
</div>
<div className='demoLoginModalBody'>
<div className='demoLoginModalWrap'>
<span className='ponTxt1'>Rupee Login (INR)</span>
<button id='demologinBtn' onClick={() => demoLogin()}>Demo (INR)</button>
<span className='ponTxt2'>Point Login (HKD)</span>
<button id='demologinBtn2' onClick={() => demoLogin2()}>Demo2 (HKD)</button>
</div>
</div>
</div>
</div>
</>
)
}

export default DemoLogin;
