import React from 'react';
import $ from 'jquery';

const Lu7Rules = () => {

const closeLu7Rules = () => {
$('#lu7Modal').hide();
}

return (
<>
<div className='rulesModal' id='lu7Modal'>
<div className='rulesModCon'>
<div className='rulesHeader'>
<span id='rulesHedTxt'>lucky7 rules</span>
<i className='fa fa-close' onClick={closeLu7Rules}></i>
</div>
<div className='rulesBody'>
<div className='rulesImgDiv'>
<img src='/images/lu7.jpg' alt='lu7' />
</div>
</div>
</div>
</div>
</>
)
}

export default Lu7Rules;
