import React, { useEffect } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../context/AppContext';

const Social = () => {

const { thSocial } = GlobalContext();

useEffect(() => {
function themeTime(){
var ThemeDateTime = new Date().toLocaleString();
$('#currTime_themeSpan').text(ThemeDateTime);
}

setInterval(themeTime, 1000);
},[]);

return (
<>
<div className='socialHeadWrap'>
<div className='socialHead'>
<div className='col'>
<div className='socialContent'>
<i className='fa fa-instagram' />
{
thSocial.length > 0 && thSocial[0].instaLink !== '' ?
<Link to={thSocial[0].instaLink} target='blank'><span>instagram</span></Link>
:
<span>instagram</span>
}
</div>
<div className='socialContent'>
<i className='fa fa-facebook' />
{
thSocial.length > 0 && thSocial[0].fbLink !== '' ?
<Link to={thSocial[0].fbLink} target='blank'><span>facebook</span></Link>
:
<span>facebook</span>
}
</div>
<div className='socialContent'>
<i className='fa fa-twitter' />
{
thSocial.length > 0 && thSocial[0].twitLink !== '' ?
<Link to={thSocial[0].twitLink} target='blank'><span>twitter</span></Link>
:
<span>twitter</span>
}
</div>
<div className='socialContent'>
<i className='fa fa-telegram' />
{
thSocial.length > 0 && thSocial[0].teleLink !== '' ?
<Link to={thSocial[0].teleLink} target='blank'><span>telegram</span></Link>
:
<span>telegram</span>
}
</div>
<div className='socialContent'>
<i className='fa fa-whatsapp' />
{
thSocial.length > 0 && thSocial[0].whatsapp !== '' ?
<Link to={'http://wa.me/'+thSocial[0].whatsapp} target='blank'><span>whatsapp</span></Link>
:
<span>whatsapp</span>
}
</div>
</div>
<div className='col'>
<div className='currTime_theme'><span id='currTime_themeSpan'>{new Date().toLocaleString()}</span></div>
</div>
</div>
</div>
</>
)
}

export default Social;
