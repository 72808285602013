import React from 'react';

const LudoMain = () => {
return (
<>
<div className='lodoGameWrap'>
<div className='lodoGameContent'>
<div className='ludoBody'>

<div className='playerDiceDiv'>
  <div className='row'>
    <div className='col'>
      <div className='diceBtn diceBtnLeft'>
      <img src='/images/ludoKing/greenCoin_sm.png' alt='greenCoin_sm' />
      </div>
      <div className='diceBox'>
        <div className='diceBox_div'>
         <img src='/images/ludoKing/dice6.png' alt='dice1' />
        </div>
      </div>
    </div>

    <div className='col'>
      <div className='diceBox'>
        <div className='diceBox_div'>
         <img src='/images/ludoKing/dice6.png' alt='dice1' />
        </div>
      </div>
      <div className='diceBtn diceBtnRight'>
      <img src='/images/ludoKing/yellowCoin_sm.png' alt='yellowCoin_sm' />
      </div>
    </div>
  </div>
</div>

<div className='lodoBodyCon'>
<div className='ludoGame'>
<div className='ludoBox1'>
<div className='house houseGreen'>
<div className='houseBox'>
<div className='pile pileBox1 pileGreen'>
<span className='activeCircle'></span>
<img src='/images/ludoKing/greenCoin_sm.png' alt='greenCoin_sm' />
</div>
<div className='pile pileBox2 pileGreen'>
<span className='activeCircle'></span>
<img src='/images/ludoKing/greenCoin_sm.png' alt='greenCoin_sm' />
</div>
<div className='pile pileBox3 pileGreen'>
<span className='activeCircle'></span>
<img src='/images/ludoKing/greenCoin_sm.png' alt='greenCoin_sm' />
</div>
<div className='pile pileBox4 pileGreen'>
<span className='activeCircle'></span>
<img src='/images/ludoKing/greenCoin_sm.png' alt='greenCoin_sm' />
</div>
</div>
</div>

<div className='houseCelldiv'>
<div className='col'>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells'>
<img src='/images/ludoKing/star.png' alt='star' />
</div>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells'></div>
</div>
<div className='col'>
<div className='cells'>
<img src='/images/ludoKing/curve-down-arrow.png' alt='down-arrow' />
</div>
<div className='cells cellsYellow'></div>
<div className='cells cellsYellow'></div>
<div className='cells cellsYellow'></div>
<div className='cells cellsYellow'></div>
<div className='cells cellsYellow'></div>
</div>
<div className='col'>
<div className='cells'></div>
<div className='cells cellsYellow'></div>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells'></div>
</div>
</div>

<div className='house houseYellow'>
<div className='houseBox'>
<div className='pile pileBox1 pileYellow'>
<span className='activeCircle'></span>
<img src='/images/ludoKing/yellowCoin_sm.png' alt='yellowCoin_sm' />
</div>
<div className='pile pileBox2 pileYellow'>
<span className='activeCircle'></span>
<img src='/images/ludoKing/yellowCoin_sm.png' alt='yellowCoin_sm' />
</div>
<div className='pile pileBox3 pileYellow'>
<span className='activeCircle'></span>
<img src='/images/ludoKing/yellowCoin_sm.png' alt='yellowCoin_sm' />
</div>
<div className='pile pileBox4 pileYellow'>
<span className='activeCircle'></span>
<img src='/images/ludoKing/yellowCoin_sm.png' alt='yellowCoin_sm' />
</div>
</div>
</div>
</div>

<div className='ludoBoxCenterDiv'>
<div className='ludoBoxCenter'>
<div className='col'>
<div className='cells'></div>
<div className='cells cellsGreen'></div>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells'></div>
</div>
<div className='col'>
<div className='cells'>
<img src='/images/ludoKing/curve-down-arrow.png' alt='down-arrow' className='rotate275' />
</div>
<div className='cells cellsGreen'></div>
<div className='cells cellsGreen'></div>
<div className='cells cellsGreen'></div>
<div className='cells cellsGreen'></div>
<div className='cells cellsGreen'></div>
</div>
<div className='col'>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells'><img src='/images/ludoKing/star.png' alt='star' /></div>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells'></div>
</div>
</div>

<div className='ludoBoxCenterBox'>
  <div className='polyDiv polyDivYellow'></div>
  <div className='polyDiv polyDivGreen'></div>
  <div className='polyDiv polyDivRed'></div>
  <div className='polyDiv polyDivBlue'></div>
</div>

<div className='ludoBoxCenter'>
<div className='col'>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells'><img src='/images/ludoKing/star.png' alt='star' /></div>
<div className='cells'></div>
<div className='cells'></div>
</div>
<div className='col'>
<div className='cells cellsBlue'></div>
<div className='cells cellsBlue'></div>
<div className='cells cellsBlue'></div>
<div className='cells cellsBlue'></div>
<div className='cells cellsBlue'></div>
<div className='cells'>
<img src='/images/ludoKing/curve-down-arrow.png' alt='down-arrow' className='rotate90' />
</div>
</div>
<div className='col'>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells cellsBlue'></div>
<div className='cells'></div>
</div>
</div>
</div>

<div className='ludoBox1'>
<div className='house houseRed'>
<div className='houseBox'>
<div className='pile pileBox1 pileRed'>
<span className='activeCircle'></span>
<img src='/images/ludoKing/redCoin_sm.png' alt='redCoin_sm' />
</div>
<div className='pile pileBox2 pileRed'>
<span className='activeCircle'></span>
<img src='/images/ludoKing/redCoin_sm.png' alt='redCoin_sm' />
</div>
<div className='pile pileBox3 pileRed'>
<span className='activeCircle'></span>
<img src='/images/ludoKing/redCoin_sm.png' alt='redCoin_sm' />
</div>
<div className='pile pileBox4 pileRed'>
<span className='activeCircle'></span>
<img src='/images/ludoKing/redCoin_sm.png' alt='redCoin_sm' />
</div>
</div>
</div>

<div className='houseCelldiv'>
<div className='col'>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells cellsRed'></div>
<div className='cells'></div>
</div>
<div className='col'>
<div className='cells cellsRed'></div>
<div className='cells cellsRed'></div>
<div className='cells cellsRed'></div>
<div className='cells cellsRed'></div>
<div className='cells cellsRed'></div>
<div className='cells'>
<img src='/images/ludoKing/curve-down-arrow.png' alt='down-arrow' className='rotateArr180' />
</div>
</div>
<div className='col'>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells'></div>
<div className='cells'><img src='/images/ludoKing/star.png' alt='star' /></div>
<div className='cells'></div>
<div className='cells'></div>
</div>
</div>

<div className='house houseBlue'>
<div className='houseBox'>
<div className='pile pileBox1 pileBlue'>
<span className='activeCircle'></span>
<img src='/images/ludoKing/blueCoin_sm.png' alt='blueCoin_sm' />
</div>
<div className='pile pileBox2 pileBlue'>
<span className='activeCircle'></span>
<img src='/images/ludoKing/blueCoin_sm.png' alt='blueCoin_sm' />
</div>
<div className='pile pileBox3 pileBlue'>
<span className='activeCircle'></span>
<img src='/images/ludoKing/blueCoin_sm.png' alt='blueCoin_sm' />
</div>
<div className='pile pileBox4 pileBlue'>
<span className='activeCircle'></span>
<img src='/images/ludoKing/blueCoin_sm.png' alt='blueCoin_sm' />
</div>
</div>
</div>
</div>
</div>
</div>

<div className='playerDiceDiv playerDiceDivFoot'>
<div className='row'>
<div className='col'>
<div className='diceBtn diceBtnLeft'>
<img src='/images/ludoKing/redCoin_sm.png' alt='redCoin_sm' />
</div>
<div className='diceBox'>
<div className='diceBox_div'>
<img src='/images/ludoKing/dice6.png' alt='dice1' />
</div>
</div>
</div>

<div className='col'>
<div className='diceBox'>
<div className='diceBox_div'>
<img src='/images/ludoKing/dice6.png' alt='dice1' />
</div>
</div>
<div className='diceBtn diceBtnRight'>
<img src='/images/ludoKing/blueCoin_sm.png' alt='blueCoin_sm' />
</div>
</div>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default LudoMain;
