import $ from 'jquery';
import axios from 'axios';
import { getAllBetsMarket } from '../../../allBets/Betsmarket';

function oddsClick_t201day(sid,type){
let betRate = 0;
var teamname = '';
if(type === 'back'){
betRate = $('#casRateBack_t201day_'+sid).text();
}
else{
betRate = $('#casRateLay_t201day_'+sid).text();    
}

if(sid === '1'){
teamname = 'player a';   
}
else{
teamname = 'player b';  
}

$('#selBetType_t201day').text(type);
$('#teamCas_t201day').text(teamname);
$('#bet_rate_t201day').val(betRate);
$('#bet_amount_t201day').val(0);
$('#teamSid_t201day').text(sid);
$('#placeBetCasino_t201day').show();
$('#betsPlace_t201day').removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_t201day').removeClass('active').attr('disabled', 'disabled');
$('#plsCasBef_t201day_1').css({color: '#000000'}).text('('+0+')');
$('#plsCasBef_t201day_2').css({color: '#000000'}).text('('+0+')');
}

function cancelBet_t201day(){
$('#placeBetCasino_t201day').hide();
$('#selBetType_t201day').text('');
$('#teamCas_t201day').text('');
$('#bet_rate_t201day').val();
$('#teamSid_t201day').text('');
$('#plsCasBef_t201day_1').hide();
$('#plsCasBef_t201day_2').hide();
}

function minusAmt_t201day(){
let betAmt = $('#bet_amount_t201day').val();
$('#bet_amount_t201day').val((parseFloat(betAmt) / 2).toFixed());
beforePls_t201day();
}

function plusAmt_t201day(){
let betAmt = $('#bet_amount_t201day').val();
$('#bet_amount_t201day').val((parseFloat(betAmt) + parseFloat(betAmt)).toFixed());
beforePls_t201day();
}

function changeAmt_t201day(val){
if(parseFloat(val !== '' ? val : 0) <= 0){
$('#betsPlace_t201day').removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_t201day').removeClass('active').attr('disabled', 'disabled');
$('#bet_amount_t201day').val(0);
}else{
$('#betsPlace_t201day').addClass('active').removeAttr('disabled'); 
$('#betsPlace2_t201day').addClass('active').removeAttr('disabled');    
$('#bet_amount_t201day_').val(Math.round(val));
}
beforePls_t201day();
}

function stakeBet_t201day(amt){
$('#bet_amount_t201day').val(amt);
$('#betsPlace_t201day').addClass('active').removeAttr('disabled');
$('#betsPlace2_t201day').addClass('active').removeAttr('disabled');
beforePls_t201day();
}

function beforePls_t201day(){
var bettype = $('#selBetType_t201day').text();
let sid = $('#teamSid_t201day').text();
let rate = $('#bet_rate_t201day').val();
let betAmt = $('#bet_amount_t201day').val(); 
let a = 0;
let b = 0;

a = parseFloat((rate * betAmt / 100).toFixed(2)).toLocaleString();
b = betAmt;

if(sid === '1'){
if(bettype === 'back'){
$('#plsCasBef_t201day_1').css({color: '#44B10A'}).show().text('('+a+')');
$('#plsCasBef_t201day_2').css({color: '#bd0000'}).show().text('('+b+')');
}
else{
$('#plsCasBef_t201day_1').css({color: '#bd0000'}).show().text('('+a+')');
$('#plsCasBef_t201day_2').css({color: '#44B10A'}).show().text('('+b+')');
}
}
else{
if(bettype === 'back'){
$('#plsCasBef_t201day_2').css({color: '#44B10A'}).show().text('('+a+')');
$('#plsCasBef_t201day_1').css({color: '#bd0000'}).show().text('('+b+')');
}
else{
$('#plsCasBef_t201day_2').css({color: '#bd0000'}).show().text('('+a+')');
$('#plsCasBef_t201day_1').css({color: '#44B10A'}).show().text('('+b+')');
}
}
}

function betSend_t201day(){
$('#betMsgWrapCas_t201day').css({display: 'grid'});
$('.odds_cas').attr('disabled', 'disabled');
setTimeout(function(){
finalPlaceBet_t201day();
},2000);
}

async function finalPlaceBet_t201day(){
var selBetType_t201day = $('#selBetType_t201day').text();
var sec = $('#sec_t201day').text();
var sport = $('#sport_t201day').text();
var eventname = 'casino';
var teamCas_t201day = $('#teamCas_t201day').text();
var bet_rate_t201day = $('#bet_rate_t201day').val();
var bet_amount_t201day = $('#bet_amount_t201day').val();
var eventid = $('#eventid_t201day').text();
var userCurr = $('#userCurr_t201day').text();
var betdelay = 2;
let sid = $('#teamSid_t201day').text();

let minBet = 0;
if(userCurr === 'inr'){
minBet = 100;
}
else{
minBet = 1;  
}

if(eventid === '0'){
$('#bet_msgCas_t201day').show().html('roundid changed');
setTimeout(function(){
$('#betMsgWrapCas_t201day').css({display: 'none'});
$('#bet_msgCas_t201day').hide().html('');
$('.odds_cas').removeAttr('disabled');
},1000);
return false;
}
if(bet_amount_t201day === '' || bet_amount_t201day < minBet){
$('#bet_msgCas_t201day').show().html('minimum betamount ' + minBet);
setTimeout(function(){
$('#betMsgWrapCas_t201day').css({display: 'none'});
$('#bet_msgCas_t201day').hide().html('');
$('.odds_cas').removeAttr('disabled');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server/casino_saveBet', {
selBetType: selBetType_t201day,
sec: sec,
sport: sport,
eventname: eventname,
teamCas: teamCas_t201day,
bet_rate: bet_rate_t201day,
bet_amount: bet_amount_t201day,
eventid: eventid,
betdelay: betdelay,
sid: sid
});
const resData = await sendData.data;
if(resData === 'success'){
setExposure();
getAllBetsMarket(eventid);
pls_t201day(eventid,sport);
$('#bet_msgCas_t201day').show().html('bet placed');
setTimeout(function(){
$('#betMsgWrapCas_t201day').css({display: 'none'});
$('#bet_msgCas_t201day').hide().html('');
$('.odds_cas').removeAttr('disabled');
cancelBet_t201day();
},1000);
return false;
}
else{
$('#bet_msgCas_t201day').show().html(resData);
setTimeout(function(){
$('#betMsgWrapCas_t201day').css({display: 'none'});
$('#bet_msgCas_t201day').hide().html('');
$('.odds_cas').removeAttr('disabled');
cancelBet_t201day();
},1000);
}
} catch (error) {
console.log('frontend t20_oneday_send_savebet data : ' + error);
}
}
}

async function setExposure(){
let currBal = 0;
let currExpo = 0;
const getBal = await axios.get('/server/active-customer');
const balRes = await getBal.data;
if(balRes.length > 0){
currBal = balRes[0].total_balance;
}

const getExpo = await axios.get('/server/user-exposure');
const expoRes = await getExpo.data;
if(expoRes.length > 0){
if(expoRes[0].exposure !== null){
currExpo = expoRes[0].exposure;
}
}

$('#mainBal').html(parseFloat((currBal - currExpo).toFixed(2)).toLocaleString());
$('#mainExpo').html(parseFloat((currExpo).toFixed(2)).toLocaleString());
//console.log('curr balance : '+currBal +' exposure : '+ currExpo);

}

async function pls_t201day(eventid,sport){
try {
const getData = await axios.get('/server/all-bets');
const resData = await getData.data;
const response = resData.filter((e) => e.sport === sport && e.eventid === eventid);
if(response.length > 0){
var t1Prf = 0;
var t2Prf = 0;
var runner1 = 'player a';
var runner2 = 'player b';

response.forEach((exx) => {
if(exx.team.trim() === runner1 && exx.bettype === 'back'){
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
}

if(exx.team.trim() === runner2 && exx.bettype === 'back'){
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
}

if(exx.team.trim() === runner1 && exx.bettype === 'lay'){
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
}

if(exx.team.trim() === runner2 && exx.bettype === 'lay'){
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
}
});

if(t1Prf > 0){
$('#plsCas_t201day_1').html(parseFloat(t1Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#plsCas_t201day_1').html(parseFloat(t1Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}

if(t2Prf > 0){
$('#plsCas_t201day_2').html(parseFloat(t2Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#plsCas_t201day_2').html(parseFloat(t2Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}

}
} catch (error) {
console.log('frontend t20_oneday_pls bets api : ' + error);
}
}

export {
oddsClick_t201day,
cancelBet_t201day,
minusAmt_t201day,
plusAmt_t201day,
changeAmt_t201day,
stakeBet_t201day,
betSend_t201day,
pls_t201day
};