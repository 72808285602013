import React from 'react';
import $ from 'jquery';

const LoginModal = () => {

const loginUser = () => {
var customer = $('#clientUser').val();
var password = $('#clientPass').val();
//console.log(customer +' -- '+ password);
if(customer === ''){
$("#msgAlert").fadeIn().addClass('alert-danger').removeClass('alert-success').html('username required');
setTimeout(function(){
$("#msgAlert").hide();
},1000);
return false;
}
if(password === ''){
$("#msgAlert").fadeIn().addClass('alert-danger').removeClass('alert-success').html('password required');
setTimeout(function(){
$("#msgAlert").hide();
},1000);
return false;
}
else{
$('#AcceptAgeModal').show();
$('#clientUserModal').val(customer);
$('#clientPassModal').val(password);
}
}

const closeLogin = () => {
$('#loginForm').hide();    
$('#clientUser').val('');
$('#clientPass').val('');
}

const showLoginPass = () => {
var pas = document.getElementById('clientPass');
if(pas.type === 'password'){
pas.type = 'text';
$('#iconPassEye').addClass('fa-eye').removeClass('fa-eye-slash');
}  
else{
pas.type = 'password';
$('#iconPassEye').addClass('fa-eye-slash').removeClass('fa-eye');
}
}

const demoLoginShow = () => {
$('#demoLoginModal').show();
}

const ChnPassFor = () => {
$('#forPassModal').show();
}

return (
<>
<div className='loginForm' id='loginForm'>
<div className='loginBody'>
<i className='fa fa-close' onClick={() => closeLogin()}></i>
<div className='formWrapLogin'>
<div className='logo_log_wrap'>
<div className='imgBoxLog'>
<img src='/images/loginLogo.png' alt='loginLogo' />
</div>
</div>

<div className='input-div'>
<input type='text' id='clientUser' placeholder='Username...' />
</div> 
<div className='input-div'>
<label>
<i id='iconPassEye' className='fa fa-eye-slash' onClick={showLoginPass}></i>
<input type='password' id='clientPass' placeholder='Password...' />
</label>
</div> 
<div className='input-divForgot'>
<span onClick={ChnPassFor}>forgot password ?</span>
</div>
<div className='form-login-btn'>
<button onClick={() => loginUser()}>Login</button>
<button onClick={() => demoLoginShow()}>Demo Login</button>
</div>  

</div>
</div>
</div>
</>
)
}

export default LoginModal;
