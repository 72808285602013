import React, { useState } from 'react';
import $ from 'jquery';
import EditUpi from '../modals/EditUpi';
import EditBank from '../modals/EditBank';
import { GlobalContext } from '../context/AppContext';

const ViewAc = ({setViewAcCurr, upiMatch, bankMatch}) => {

const { upiData, bankData, customer } = GlobalContext();
const [editUpiData, setEditUpiData] = useState([]);
const [editBankData, setEditBankData] = useState([]);

const closeViewAc = () => {
setViewAcCurr(null);
$('.payOptBtn').removeClass('active');
}

const opentUpiUpd = () => {
if(upiMatch.length > 0){
$('#userUpiUpdModal').show(); 
if(upiData.length > 0 && customer.length > 0){
setEditUpiData(upiData.filter((e) => e.customer === customer[0].customer));
}
}
}

const opentBnkUpd = () => {
if(bankMatch.length > 0){
$('#userBankUpdModal').show(); 
if(bankData.length > 0 && customer.length > 0){
setEditBankData(bankData.filter((e) => e.customer === customer[0].customer));
}
}
}

return (
<>
<div className='paymentDataDiv'>
<div className='payHead'>
<span>view account</span>
<span className='rightTxt' onClick={closeViewAc}>close view ac <i className='fa fa-close' /></span>
</div>

<div className='payBody'>

{
upiMatch.length <= 0 && bankMatch.length <= 0 ?
<div className='pendingPayDiv'>
<div className='pendingContent'>
<img src='/images/bank-transfer.png' alt='pending' />
<span>add your upi/bank account first</span>
<span>you have no any upi and bank account.</span>
</div>
</div>
:
<div className='cardUpiAcWrap'>
<div className='cardUpiAc' onClick={opentUpiUpd}>
<span>
<i className='fa fa-edit'></i>
<i className='fa fa-trash'></i>
</span>
<p>update/remove upi</p>
</div>

<div className='cardUpiAc' onClick={opentBnkUpd}>
<span>
<i className='fa fa-edit'></i>
<i className='fa fa-trash'></i>
</span>
<p>update/remove bank</p>
</div>
</div>

}

</div>
</div>

<EditUpi editUpiData={editUpiData} setEditUpiData={setEditUpiData} />
<EditBank editBankData={editBankData} setEditBankData={setEditBankData} />
</>
)
}

export default ViewAc;
