import $ from 'jquery';
import axios from 'axios';
import { getAllBetsMarket } from '../../../allBets/Betsmarket';

var allSid_ab = [];

function abClick(sid,team,rate){
$('#teamCas_ab').text(team);
$('#bet_rate_ab').val(rate);
$('#bet_amount_ab').val(0);
$('#teamSid_ab').text(sid);
$('#placeBetCasino_ab').show();
$('#betsPlace_ab').removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_ab').removeClass('active').attr('disabled', 'disabled');
}

function minusAmt_ab(){
let betAmt = $('#bet_amount_ab').val();
$('#bet_amount_ab').val((parseFloat(betAmt) / 2).toFixed());
}

function plusAmt_ab(){
let betAmt = $('#bet_amount_ab').val();
$('#bet_amount_ab').val((parseFloat(betAmt) + parseFloat(betAmt)).toFixed());
}

function changeAmt_ab(val){
if(parseFloat(val !== '' ? val : 0) <= 0){
$('#betsPlace_ab').removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_ab').removeClass('active').attr('disabled', 'disabled');
$('#bet_amount_ab').val(0);
}else{
$('#betsPlace_ab').addClass('active').removeAttr('disabled'); 
$('#betsPlace2_ab').addClass('active').removeAttr('disabled');    
$('#bet_amount_ab').val(Math.round(val));
}
}

function cancelBet_ab(){
$('#placeBetCasino_ab').hide();
$('#teamCas_ab').text('');
$('#bet_rate_ab').val();
$('#teamSid_ab').text('');
}

function stakeBet_ab(amt){
$('#bet_amount_ab').val(amt);
$('#betsPlace_ab').addClass('active').removeAttr('disabled');
$('#betsPlace2_ab').addClass('active').removeAttr('disabled');
}

function betSend_ab(){
$('#betMsgWrapCas_ab').css({display: 'grid'});
setTimeout(function(){
finalPlaceBet_ab();
},2000);
}

async function finalPlaceBet_ab(){
var selBetType = $('#selBetType_ab').text();
var sec = $('#sec_ab').text();
var sport = $('#sport_ab').text();
var eventname = 'casino';
var teamCas_ab = $('#teamCas_ab').text();
var bet_rate_ab = $('#bet_rate_ab').val();
var bet_amount_ab = $('#bet_amount_ab').val();
var eventid = $('#eventid_ab').text();
var userCurr = $('#userCurr_ab').text();
var betdelay = 2;
let sid = $('#teamSid_ab').text();

let minBet = 0;
if(userCurr === 'inr'){
minBet = 100;
}
else{
minBet = 1;  
}

if(eventid === '0'){
$('#bet_msgCas_ab').show().html('roundid changed');
setTimeout(function(){
$('#betMsgWrapCas_ab').css({display: 'none'});
$('#bet_msgCas_ab').hide().html('');
},1000);
return false;
}
if(bet_amount_ab === '' || bet_amount_ab < minBet){
$('#bet_msgCas_ab').show().html('minimum betamount ' + minBet);
setTimeout(function(){
$('#betMsgWrapCas_ab').css({display: 'none'});
$('#bet_msgCas_ab').hide().html('');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server/casino_saveBet', {
selBetType: selBetType,
sec: sec,
sport: sport,
eventname: eventname,
teamCas: teamCas_ab,
bet_rate: bet_rate_ab,
bet_amount: bet_amount_ab,
eventid: eventid,
betdelay: betdelay,
sid: sid
});
const resData = await sendData.data;
if(resData === 'success'){
setExposure();
getAllBetsMarket(eventid);
pls_ab(eventid,sport);
$('#bet_msgCas_ab').show().html('bet placed');
setTimeout(function(){
$('#betMsgWrapCas_ab').css({display: 'none'});
$('#bet_msgCas_ab').hide().html('');
cancelBet_ab();
},1000);
return false;
}
else{
$('#bet_msgCas_ab').show().html(resData);
setTimeout(function(){
$('#betMsgWrapCas_ab').css({display: 'none'});
$('#bet_msgCas_ab').hide().html('');
cancelBet_ab();
},1000);
}
} catch (error) {
console.log('frontend ab_send_savebet data : ' + error);
}
}
}

async function setExposure(){
let currBal = 0;
let currExpo = 0;
const getBal = await axios.get('/server/active-customer');
const balRes = await getBal.data;
if(balRes.length > 0){
currBal = balRes[0].total_balance;
}

const getExpo = await axios.get('/server/user-exposure');
const expoRes = await getExpo.data;
if(expoRes.length > 0){
if(expoRes[0].exposure !== null){
currExpo = expoRes[0].exposure;
}
}

$('#mainBal').html(parseFloat((currBal - currExpo).toFixed(2)).toLocaleString());
$('#mainExpo').html(parseFloat((currExpo).toFixed(2)).toLocaleString());
//console.log('curr balance : '+currBal +' exposure : '+ currExpo);

}

async function pls_ab(eventid,sport){
try {
const getData = await axios.get('/server/all-bets');
const resData = await getData.data;
const response = resData.filter((e) => e.sport === sport && e.eventid === eventid);
if(response.length > 0){
const reduceData = response.reduce((c, e) => {
const { team, loss } = e;
const cteam = c.find((h) => h.team === team);
let lossNew = 0;
lossNew = lossNew + loss;
cteam ? cteam.lossNew += lossNew : c.push({team,lossNew});
return c;
},[]);

reduceData.length > 0 && reduceData.forEach(element => {
allSid_ab.forEach((ele) => {
if(element.team === ele.nat){
$('#ab_Pls_'+ele.sid).html(parseFloat(element.lossNew.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
});
});

}
} catch (error) {
console.log('frontend ab_pls bets api : ' + error);
}
}

export {
abClick,
cancelBet_ab,
minusAmt_ab,
plusAmt_ab,
changeAmt_ab,
betSend_ab,
stakeBet_ab,
allSid_ab,
pls_ab
}