import axios from 'axios';
import $ from 'jquery';

var runSettle_dt202;

function startSettle_dt202(){
runSettle_dt202 = setInterval(autoSettle_dt202, 10000);  
}

async function autoSettle_dt202(){
try {
//console.log('settlement run');
const getData = await axios.get('/server/dt202Settle');
const resData = await getData.data;
if(resData === 'success'){
//console.log('settlement clear');
setExposure();
}
else{
console.log(resData);
}
} catch (error) {
console.log('frontend autoSettle_dt202_dt202_data error : ' + error);
}
}

async function setExposure(){
let currBal = 0;
let currExpo = 0;
const getBal = await axios.get('/server/active-customer');
const balRes = await getBal.data;
if(balRes.length > 0){
currBal = balRes[0].total_balance;
}

const getExpo = await axios.get('/server/user-exposure');
const expoRes = await getExpo.data;
if(expoRes.length > 0){
if(expoRes[0].exposure !== null){
currExpo = expoRes[0].exposure;
}
}

$('#mainBal').html(parseFloat((currBal - currExpo).toFixed(2)).toLocaleString());
$('#mainExpo').html(parseFloat((currExpo).toFixed(2)).toLocaleString());
//console.log('curr balance : '+currBal +' exposure : '+ currExpo);

}

export {
runSettle_dt202,
startSettle_dt202
};