import React from 'react';
import $ from 'jquery';

const ResultTs = () => {

const closeDetResTs = () => {
$('#casResModalTs').hide();
}

return (
<>
<div className='casResModal' id='casResModalTs'>
<div className='casResCont'>
<div className='casResHead'><span>details result</span> <i className='fa fa-close' onClick={closeDetResTs}></i></div>
<div className='casResBody'>

<div className='casDetResLoadTs'>
<span></span>
</div>

<div className='resCasConTs'>
<div className='cardsRow'>
<div className='col'>
<span className='cardTeamCas'>tiger</span>
<div className='cardsImg'>
<img id='img1Ts' alt='card_img' />
<img id='img2Ts' alt='card_img' />
<img id='img3Ts' alt='card_img' />
</div>
</div>
<div className='col'>
<span className='cardTeamCas'>lion</span>
<div className='cardsImg'>
<img id='img4Ts' alt='card_img' />
<img id='img5Ts' alt='card_img' />
<img id='img6Ts' alt='card_img' />
</div>
</div>
<div className='col'>
<span className='cardTeamCas'>dragon</span>
<div className='cardsImg'>
<img id='img7Ts' alt='card_img' />
<img id='img8Ts' alt='card_img' />
<img id='img9Ts' alt='card_img' />
</div>
</div>
</div>

<div className='casResWin'>
<span className='casRestxt'>winner : <span id='winCasTs'></span></span>
<span className='casRestxt'>mid : <span id='winCasMidTs'></span></span>
</div>
</div>

<div className='record_no' id='record_no_teenTest'>
<img src='/images/poker_rec.png' alt='poker_rec' />   
<span>no records found...</span>
</div>

</div>
</div>
</div>
</>
)
}

export default ResultTs;
