import $ from 'jquery';
import axios from 'axios';
import { getAllBetsMarket } from '../../../allBets/Betsmarket';

var allDt1daySid = [];

function oddsClick_dt1day(sid,team,type){
let betRate = 0;
$('#selBetType_dt1day_'+sid).text(type);
$('#teamCas_dt1day_'+sid).text(team);
$('#bet_amount_t201day').val(0);
$('#teamSid_dt1day_'+sid).text(sid);

if(type === 'back'){
betRate = $('#casRateBack_dt1day_'+sid).text();
}
else{
betRate = $('#casRateLay_dt1day_'+sid).text(); 
}

$('#bet_rate_dt1day_'+sid).val(betRate);
$('.placeBetCasino_dt1day').hide();
$('#placeBetCasino_dt1day_'+sid).show();
$('#betsPlace_dt1day_'+sid).removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_dt1day_'+sid).removeClass('active').attr('disabled', 'disabled');
$('.plsCasBef').hide();
$('#plsCasBef_dt1day_'+sid).show().text('('+0+')').css({color: '#000000'});
}

function cancelBet_dt1day(sid){
$('#placeBetCasino_dt1day_'+sid).hide();
$('#selBetType_dt1day_'+sid).text('');
$('#teamCas_dt1day_'+sid).text('');
$('#bet_rate_dt1day_'+sid).val();
$('#teamSid_dt1day_'+sid).text('');
$('#plsCasBef_dt1day_'+sid).hide();
}

function minusAmt_dt1day(sid){
let betAmt = $('#bet_amount_dt1day_'+sid).val();
$('#bet_amount_dt1day_'+sid).val((parseFloat(betAmt) / 2).toFixed());
beforePls_dt1day(sid);
}

function plusAmt_dt1day(sid){
let betAmt = $('#bet_amount_dt1day_'+sid).val();
$('#bet_amount_dt1day_'+sid).val((parseFloat(betAmt) + parseFloat(betAmt)).toFixed());
beforePls_dt1day(sid);
}


function beforePls_dt1day(sid){
var bettype = $('#selBetType_dt1day_'+sid).text();
let rate = $('#bet_rate_dt1day_'+sid).val();
let betAmt = $('#bet_amount_dt1day_'+sid).val(); 
let a = 0;
a = parseFloat((rate * betAmt - betAmt).toFixed(2)).toLocaleString();

if(bettype === 'back'){
$('#plsCasBef_dt1day_'+sid).css({color: '#44B10A'}).show().text('('+a+')');
}
else{
$('#plsCasBef_dt1day_'+sid).css({color: '#bd0000'}).show().text('('+a+')');
}

}

function changeAmt_dt1day(sid,val){
if(parseFloat(val !== '' ? val : 0) <= 0){
$('#betsPlace_dt1day_'+sid).removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_dt1day_'+sid).removeClass('active').attr('disabled', 'disabled');
$('#bet_amount_dt1day_'+sid).val(0);
}else{
$('#betsPlace_dt1day_'+sid).addClass('active').removeAttr('disabled'); 
$('#betsPlace2_dt1day_'+sid).addClass('active').removeAttr('disabled');    
$('#bet_amount_dt1day_'+sid).val(Math.round(val));
}
beforePls_dt1day(sid);
}

function stakeBet_dt1day(sid,amt){
$('#bet_amount_dt1day_'+sid).val(amt);
$('#betsPlace_dt1day_'+sid).addClass('active').removeAttr('disabled');
$('#betsPlace2_dt1day_'+sid).addClass('active').removeAttr('disabled');
beforePls_dt1day(sid);
}

function betSend_dt1day(sid){
$('#betMsgWrapCas_dt1day_'+sid).css({display: 'grid'});
$('.odds_cas').attr('disabled', 'disabled');
setTimeout(function(){
finalPlaceBet_dt1day(sid);
},2000);
}

async function finalPlaceBet_dt1day(sidd){
var selBetType_dt1day = $('#selBetType_dt1day_'+sidd).text();
var sec = $('#sec_dt1day_'+sidd).text();
var sport = $('#sport_dt1day_'+sidd).text();
var eventname = 'casino';
var teamCas_dt1day = $('#teamCas_dt1day_'+sidd).text();
var bet_rate_dt1day = $('#bet_rate_dt1day_'+sidd).val();
var bet_amount_dt1day = $('#bet_amount_dt1day_'+sidd).val();
var eventid = $('#eventid_dt1day_'+sidd).text();
var userCurr = $('#userCurr_dt1day_'+sidd).text();
var betdelay = 2;
let sid = $('#teamSid_dt1day_'+sidd).text();

let minBet = 0;
if(userCurr === 'inr'){
minBet = 100;
}
else{
minBet = 1;  
}

if(eventid === '0'){
$('#bet_msgCas_dt1day_'+sidd).show().html('roundid changed');
setTimeout(function(){
$('#betMsgWrapCas_dt1day_'+sidd).css({display: 'none'});
$('#bet_msgCas_dt1day_'+sidd).hide().html('');
$('.odds_cas').removeAttr('disabled');
},1000);
return false;
}
if(bet_amount_dt1day === '' || bet_amount_dt1day < minBet){
$('#bet_msgCas_dt1day_'+sidd).show().html('minimum betamount ' + minBet);
setTimeout(function(){
$('#betMsgWrapCas_dt1day_'+sidd).css({display: 'none'});
$('#bet_msgCas_dt1day_'+sidd).hide().html('');
$('.odds_cas').removeAttr('disabled');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server/casino_saveBet', {
selBetType: selBetType_dt1day,
sec: sec,
sport: sport,
eventname: eventname,
teamCas: teamCas_dt1day,
bet_rate: bet_rate_dt1day,
bet_amount: bet_amount_dt1day,
eventid: eventid,
betdelay: betdelay,
sid: sid
});
const resData = await sendData.data;
if(resData === 'success'){
setExposure();
getAllBetsMarket(eventid);
pls_dt1day(eventid,sport);
$('#bet_msgCas_dt1day_'+sidd).show().html('bet placed');
setTimeout(function(){
$('#betMsgWrapCas_dt1day_'+sidd).css({display: 'none'});
$('#bet_msgCas_dt1day_'+sidd).hide().html('');
$('.odds_cas').removeAttr('disabled');
cancelBet_dt1day(sidd);
},1000);
return false;
}
else{
$('#bet_msgCas_dt1day_'+sidd).show().html(resData);
setTimeout(function(){
$('#betMsgWrapCas_dt1day_'+sidd).css({display: 'none'});
$('#bet_msgCas_dt1day_'+sidd).hide().html('');
$('.odds_cas').removeAttr('disabled');
cancelBet_dt1day(sidd);
},1000);
}
} catch (error) {
console.log('frontend dt_oneday_send_savebet data : ' + error);
}
}

}

async function setExposure(){
let currBal = 0;
let currExpo = 0;
const getBal = await axios.get('/server/active-customer');
const balRes = await getBal.data;
if(balRes.length > 0){
currBal = balRes[0].total_balance;
}

const getExpo = await axios.get('/server/user-exposure');
const expoRes = await getExpo.data;
if(expoRes.length > 0){
if(expoRes[0].exposure !== null){
currExpo = expoRes[0].exposure;
}
}

$('#mainBal').html(parseFloat((currBal - currExpo).toFixed(2)).toLocaleString());
$('#mainExpo').html(parseFloat((currExpo).toFixed(2)).toLocaleString());
//console.log('curr balance : '+currBal +' exposure : '+ currExpo);

}

async function pls_dt1day(eventid,sport){
try {
const getData = await axios.get('/server/all-bets');
const resData = await getData.data;
const response = resData.filter((e) => e.sport === sport && e.eventid === eventid);
if(response.length > 0){

const reduceDt = response.reduce((a, b) => {
const { team, bettype, profit, loss} = b;
const item = a.find((o) => o.team === team);
let newPrf = 0;
let newLos = 0;
let finalLoss = 0
if(bettype === 'back'){
newPrf = newPrf + profit;
}
else{
newLos = newLos + loss;
}

finalLoss = parseFloat(newPrf) - parseFloat(newLos);
item ? item.finalLoss += finalLoss : a.push({team,finalLoss})
return a;
},[]);

// allDt1daySid
//console.log(reduceDt);
if(reduceDt.length > 0){
reduceDt.forEach((e) => {
allDt1daySid.forEach((ee) => {
if(e.team.trim() === ee.nat){
if(e.finalLoss <= 0){
$('#plsCas_dt1day_'+ee.sid).html(parseFloat(e.finalLoss.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
else{
$('#plsCas_dt1day_'+ee.sid).html(parseFloat(e.finalLoss.toFixed(2)).toLocaleString()).css({color: '#44b10a'});  
}
}
});
});
}

}
} catch (error) {
console.log('frontend dt1day_pls bets api : ' + error);
}
}

export {
oddsClick_dt1day,
cancelBet_dt1day,
minusAmt_dt1day,
plusAmt_dt1day,
changeAmt_dt1day,
betSend_dt1day,
stakeBet_dt1day,
allDt1daySid,
pls_dt1day
};