import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import SideMenu from '../../extra/SideMenu';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../../context/AppContext';
import AllOdds from './AllOdds';
import MainFancy from './fancy/MainFancy';
import { checkUser, startData } from './js/refresh';
import AcceptAge from '../../modals/AcceptAge';
import { clearId } from './js/betting';
import { clearFancyId } from './js/fancyBetting';
import { clearLineId } from './js/lineBetting';
import MarketBets from '../../allBets/MarketBets';
import BookFancy from '../../fancyBook/BookFancy';

const apiMarket = '/server/cricket-market';
const MultiApi = '/server/customer-multimarket';

const CricketMarket = () => {

const { eventid, marketid } = useParams();
const { GetCricketMarket, isCricMarLoad, cicList, cricMarket, customer, GetMultiList, multimarket, spoSett } = GlobalContext();
const [eventname, setEventname] = useState([]);
const [lockSport, setLockSport] = useState([]);
const [lockShow, setLockShow] = useState(false);
const [scoreId, setScoreId] = useState(null);
$('.mainMenuLi').removeClass('activeMain');

const showOdds = (mid) => {
//alert(mid);
if(cricMarket.length !== 0 && cricMarket.data !== undefined){
cricMarket.data.forEach(element => {
if(mid !== 0){
if(element.mid === mid){
$('#OddsDiv_'+element.mid).show();
}
else{
$('#OddsDiv_'+element.mid).hide();
}
}
else{
$('#OddsDiv_'+element.mid).show();   
}
});
}
}

const pinMarket = async() => {
if (customer.length > 0){
var eid = '4';
var gmid = eventid;
var mid = marketid;
//alert(eid +' -- '+ gmid +' -- '+ mid);
try {
const sendData = await axios.post('/server/multimarkets', {customer:customer[0].customer, eid:eid, gmid:gmid, mid:mid});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('market pin success');
GetMultiList(`${MultiApi}`);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else if(resData === 'removed'){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('market pin removed success');
GetMultiList(`${MultiApi}`);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);   
}
} catch (error) {
console.log('frontend cricket market-multiadd-api error: ' + error);
}
}
else{
$('#AcceptAgeModal').show();
}
}

const checkMarket = multimarket.length > 0 && multimarket.filter((e) => e.gmid === eventid);

const reloadMarket = () => {
GetCricketMarket(`${apiMarket}/${eventid}`);
}

const view_score = () => {
if (customer.length > 0){
$('#scoreFrame').toggle();
setScoreId(eventid);  
}
else{
$('#AcceptAgeModal').show();
}
}

useEffect(() => {
GetCricketMarket(`${apiMarket}/${eventid}`);
checkUser(eventid);
return () => {
clearInterval(startData);
clearId();
clearFancyId();
clearLineId();
}
// eslint-disable-next-line react-hooks/exhaustive-deps
},[eventid]);

useEffect(() => {
setEventname(cicList.filter((e) => parseInt(e.gameid) === parseInt(eventid)));
},[cicList, eventid]);

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent || e.username === f.customer)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.customer)));
}
}
},[customer, spoSett]);

useEffect(() => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'cricket');
//console.log(checkLock);
if(checkLock.length > 0){
setLockShow(true);
}else{
setLockShow(false);
}
}
},[lockSport]);

if(isCricMarLoad){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='game-body'>
<div className='game-contents'>
<div className='multimarketdIV'>
<img src='/images/cricket.png' alt='highImg' /> 
{
eventname.length > 0 ? eventname.map((k, l) => {
const e  = k.eventname.split('/');
return (
<span id='eventname' key={l}>{e[0]}</span>
)
}) 
:
<span id='eventname'>no event name (market not found)</span>
}
</div>

<div className='market-divWrap'>

{
lockShow === true && 
<div className='sports_lock' id='sports_lock2' style={{display: 'flex'}}>
<div className='sport_lockTxt'>
<img src='/images/lock.png' alt='lock' />
<span>sport is locked</span>
<span>contact your service provider</span>
</div>
</div>
}

<div className='pinDiv'>
{
checkMarket.length > 0 ? 
<button onClick={() => pinMarket()} className='pinBtn1 pinBtnAct'><i className='fa fa-map-pin pinBtnIcon'></i></button>
:
<button onClick={() => pinMarket()} className='pinBtn1'><i className='fa fa-map-pin pinBtnIcon'></i></button>
}

<button onClick={() => reloadMarket()} className='pinBtn2'><i className='fa fa-refresh pinBtnIcon'></i></button>

<div className='scoreView'>
<img src='/images/speedometer.png' onClick={() => view_score()} alt='speedometer' /> 
<img src='/images/tvscore.png' alt='tvscore' /> 
</div>

</div>

{
cricMarket.length !== 0 &&
<div className='oddsAvailsBtns'>
<div className='scrollMenu'>
<button className='topOddsBtn' onClick={() => showOdds(0)}>All</button>
{
cricMarket.data !== undefined && cricMarket.data.filter((e) => e.gtype.trim() === 'match' || e.gtype.trim() === 'match1').map((data, index) => {
return (
<button className='topOddsBtn' onClick={() => showOdds(data.mid)} key={index}>{data.mname.replaceAll('_', ' ')}</button>
)
})
}
</div>
</div>
}

{
scoreId !== null &&
<iframe className='scoreFrame2' id='scoreFrame' title='score' src={'https://24digital.top/liveScore_odds/score2/'+scoreId} ></iframe>
}


<AllOdds />
<MainFancy />
<MarketBets eventid={eventid} />
<BookFancy />

</div>

</div>
</div>
</div>
</div>
</div>
</div>

<AcceptAge />
</>
)
}

export default CricketMarket;
